<template>
  <router-view />
  <app-alert />
</template>
<script>
import ROLE from "@/utils/const/role";

export default {
  beforeCreate() {
    this.$store.dispatch("authStore/checkAuth");
  },
  computed: {
    email_not_verify_error() {
      return this.$store.getters.email_not_verify_error;
    },
    user() {
      return this.$store.getters["authStore/user"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  watch: {
    email_not_verify_error(error) {
      if (error) {
        this.$notify(
          {
            group: "error",
            title: error,
          },
          5000
        );
      }
    },
    $route(to, from) {
      this.initTawktoMessenger();
    },
  },
  mounted() {
    window.addEventListener("tawkLoad", () => {
      this.initTawktoMessenger();
    });
  },
  methods: {
    initTawktoMessenger() {
      // skip tawkto if lib not loaded
      if (this.$lodash.isEmpty(window.Tawk_API)) {
        return;
      }

      // minimize tawkto after load
      this.$tawkMessenger.minimize();

      if (
        [
          ROLE.BUSINESS_OWNER,
          ROLE.BUSINESS_ADMIN,
          ROLE.BUSINESS_MANAGER,
          ROLE.BUSINESS_DEVELOPER,
          ROLE.BUSINESS_SUPPORT,
          ROLE.MASTER_BUSINESS_OWNER,
          ROLE.MASTER_BUSINESS_ADMIN,
          ROLE.MASTER_BUSINESS_MANAGER,
          ROLE.MASTER_BUSINESS_DEVELOPER,
          ROLE.MASTER_BUSINESS_SUPPORT,
        ].includes(this.selectedRole?.role_id)
      ) {
        this.$tawkMessenger.showWidget();
        this.$tawkMessenger.setAttributes({
          name: this.user?.name,
          email: this.user?.email,
        });
      } else {
        this.$tawkMessenger.hideWidget();
      }
    },
  },
};
</script>
