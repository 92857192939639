<template>
    <div class="flex flex-col" :class="$attrs.class">
        <label v-if="showlabel" :for="name" class="block text-sm font-light text-gray-700">
            {{ labelText }}
        </label>
        
        <litepie-datepicker as-single :modelValue="dateValue" @update:modelValue="valueChanged($event)"
            :formatter="dateFormat" :separator="$t('datatable.to')" v-slot="{ placeholder }">
            <button type="button" class="
          w-full
          relative
          h-10
          pr-10
          md:pr-2
          pl-2
          mt-2
          text-sm
          bg-white
          rounded-md
          shadow-sm
          focus:ring-primary
          border-none
          ring-1
          focus:ring-2 focus:outline-none
        " :class="[
            $attrs.inputclass,
            dateValue.start_date !== '' ? 'text-black' : 'text-gray-500',
            errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor',
        ]">
                <p class="text-left">
                    {{ dateValue.start_date? dateValue.start_date : placeholder }}
                </p>

                <CalendarIcon class="
            absolute
            right-0
            top-0
            mt-2
            h-6
            w-6
            m-1
            mr-2
            ml-2
            text-gray-500
          " />
            </button>
        </litepie-datepicker>
        
        <span v-if="errorMessage.length > 0" class="text-xs text-error">{{
            errorMessage[0]
        }}</span>
    </div>
</template>

<script>
import { CalendarIcon } from "@heroicons/vue/outline";
import LitepieDatepicker from "litepie-datepicker";
import moment from "moment";

export default {
    components: {
        CalendarIcon,
        LitepieDatepicker,
    },

    data() {
        return {
            dateFormat: {
                date: "DD MMMM YYYY",
                month: "MMMM",
            },
        };
    },

    computed: {
        dateValue() {

            if (this.modelValue == "" || this.modelValue == null)
                return {
                    start_date: ""
                };

            if (typeof this.modelValue === 'string')
                return {
                    start_date: moment(String(this.modelValue)).format(
                        "DD MMMM YYYY"
                    )
                };

            return {
                start_date: moment(String(this.modelValue.start_date)).format(
                    "DD MMMM YYYY"
                )
            };
        }
    },

    props: {
        // value: {
        //     type: Object,
        // },
        id: {
            type: String,
        },
        name: {
            type: String,
        },
        type: {
            type: String,
        },
        autocomplete: {
            type: String,
        },
        labelText: {
            type: String,
        },
        showlabel: {
            type: Boolean,
            default: true,
        },
        errorMessage: {
            type: Array,
            default: () => [],
        },
        serverMessage: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
            default: () => ({
                start_date: ""
            }),
        },
    },

    methods: {
        valueChanged(newVal) {

            let convertedValue = {
                start_date: moment(String(newVal.start_date)).format(
                    "YYYY-MM-DD"
                ),
            };

            this.$emit("update:modelValue", convertedValue);
        },
    },

};
</script>