import AuthRepository from "../../data/repository/auth-repository";
import TaxRepository from "../../data/repository/tax-repository";
import { logEvent } from "firebase/analytics";
import baseStore from "../base-store";

const initialTax = {
  name: null,
  description: null,
  rate: null,
};

export default {
  namespaced: true,
  state: {
    ...baseStore.state,

    loadingDetail: false,
    tax: initialTax,
  },

  getters: {
    ...baseStore.getters,

    tax(state) {
      return state.tax;
    },

    list_tax(state) {
      return state.listTax;
    },

    loadingDetail(state) {
      return state.loadingDetail;
    },
  },

  mutations: {
    ...baseStore.mutations,

    setTax(state, tax = initialTax) {
      return (state.tax = tax);
    },

    initTax(state, tax = initialTax) {
      return (state.tax = this.$lodash.clone(tax));
    },

    setListTax(state, listTax) {
      state.listTax = listTax;
    },

    setLoadingDetail(state, loadingDetail) {
      state.loadingDetail = loadingDetail;
    },
  },

  actions: {
    async retrieveTax(state, filters) {
      const taxRepository = new TaxRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const taxIndexResult = await taxRepository.indexTax(
        business_id,
        filters
      );

      if (taxIndexResult.message != undefined) {
        logEvent(this.$analytics, "tax-index", { result: "failed" });
        state.commit("setErrorMessage", taxIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "tax-index", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationResponse", taxIndexResult);
      return taxIndexResult;
    },

    async createOrUpdateTax(state, tax_form) {
      const taxRepository = new TaxRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(tax_form).forEach((key) => {
        if (tax_form[key] === "") {
          delete tax_form[key];
        }
      });

      const taxResult = await taxRepository.createOrUpdateTax(
        business_id,
        tax_form
      );

      const isCreate = tax_form._id == null;

      if (taxResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "create-tax" : "update-tax",
          { result: "failed" }
        );
        state.commit("setErrorMessage", taxResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "create-tax" : "update-tax",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return taxResult;
    },

    async getTaxById(state, tax_id) {
      const taxRepository = new TaxRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const taxResult = await taxRepository.getTaxById(
        business_id,
        tax_id
      );

      if (taxResult.message != undefined) {
        logEvent(this.$analytics, "get-tax", { result: "failed" });
        state.commit("setErrorMessage", taxResult);
        return;
      } else {
        logEvent(this.$analytics, "get-tax", { result: "success" });
      }

      state.commit("setTax", taxResult.data);
      state.commit("setLoading", false);
      return taxResult;
    },

    async resetTax(state) {
      state.commit("setTax", {
        name: '',
        description: null,
        rate: null,
      });
    },

    async deleteTax(state, tax) {
      const taxRepository = new TaxRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const taxResult = await taxRepository.deleteTax(business_id, tax);

      if (taxResult.message != undefined) {
        logEvent(this.$analytics, "delete-tax", { result: "failed" });
        state.commit("setErrorMessage", taxResult);
      } else {
        logEvent(this.$analytics, "delete-tax", { result: "success" });
      }

      state.commit("setLoading", false);

      return taxResult;
    },
  },
};
