import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class BusinessBankRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async createOrUpdate(bank) {

    bank.bank_id = (bank.bank != null) ? bank.bank.id : null;

    const response = (bank.id == null) ?
      await client.post("/businesses/" + bank.business_id + "/business-banks", bank, this.getToken()) :
      await client.put("/businesses/" + bank.business_id + "/business-banks/" + bank.id, bank, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateMasterBusiness(masterBusinessId, bank) {

    bank.bank_id = (bank.bank != null) ? bank.bank.id : 0;

    const response = (bank.id == null) ?
      await client.post("/master-businesses/" + masterBusinessId + "/businesses/" + bank.business_id + "/business-banks", bank, this.getToken()) :
      await client.put("/master-businesses/" + masterBusinessId + "/businesses/" + bank.business_id + "/business-banks/" + bank.id, bank, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
}