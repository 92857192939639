import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class UserRepository extends WithAuthRepository {
  async index(queryParams) {
    const response = await client.get("/admin/users" + queryParams, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getUser(id) {
    const response = await client.get("/admin/users/" + id, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async updateUser(data) {
    const response = await client.patch(
      "/admin/users/" + data.id,
      data,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createRoleUser(data) {
    const response = await client.post(
      "/admin/user-roles/assign",
      data,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async deleteRoleUser(id) {
    const response = await client.delete(
      "/admin/user-roles/delete/" + id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async notifications(filters = {}) {
    const response = await client.get(
      "/user/notifications",
      this.getToken(),
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
  async showNotification(notification_id) {
    const response = await client.get(
      "/user/notifications/" + notification_id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async markAsReadNotification(notification_id) {
    const response = await client.patch(
      "/user/notifications/mark-read/" + notification_id,
      {},
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async markAsReadAllNotification() {
    const response = await client.patch(
      "/user/notifications/mark-read-all",
      {},
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async notificationUnread() {
    const response = await client.get(
      "/user/notifications-unread",
      this.getToken()
    );
    
    if (!Number.isInteger(response.data)) {
      response.data = 0;
    }

    return this.successResponse(response.data);
  }
}
