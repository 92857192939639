<template>
  <div :class="$attrs.class">
    <litepie-datepicker
      as-single
      use-range
      :modelValue="dateValue"
      @update:modelValue="valueChanged($event)"
      :placeholder="placeHolderText"
      :formatter="dateFormat"
      :separator="$t('datatable.to')"
      v-slot="{ value, placeholder }"
    >
      <button
        type="button"
        class="
          w-full
          relative
          h-10
          pr-10
          md:pr-2
          pl-2
          mt-2
          text-sm
          bg-white
          rounded-md
          shadow-sm
          focus:ring-primary
          border-none
          ring-1 ring-bordercolor
          focus:ring-2 focus:outline-none
        "
        :class="[
          $attrs.inputclass,
          value !== '' ? 'text-black' : 'text-gray-500',
        ]"
      >
        <p class="text-left">
          {{ value || placeholder }}
        </p>

        <CalendarIcon
          class="
            absolute
            right-0
            top-0
            mt-2
            h-6
            w-6
            m-1
            mr-2
            ml-2
            text-gray-500
          "
        />
      </button>
    </litepie-datepicker>
  </div>
</template>

<script>
import { CalendarIcon } from "@heroicons/vue/outline";
import LitepieDatepicker from "litepie-datepicker";
import moment from "moment";

export default {
  components: {
    CalendarIcon,
    LitepieDatepicker,
  },

  data() {
    return {
      dateFormat: {
        date: "DD MMMM YYYY",
        month: "MMMM",
      },
    };
  },

  computed: {
    dateValue() {
      if (this.modelValue.start_date == "")
        return this.modelValue;

      let convertedValue = {
        start_date: moment(String(this.modelValue.start_date), "YYYY-MM-DD").format(
          "DD MMMM YYYY"
        ),
        end_date: moment(String(this.modelValue.end_date), "YYYY-MM-DD").format(
          "DD MMMM YYYY"
        ),
      };

      return convertedValue;
    },
    placeHolderText() {
      return this.placeholder == ""
        ? this.$t("account.transaction.date_from_to")
        : this.placeholder;
    },
  },

  props: {
    datas: {
      type: Array,
    },
    value: {
      type: Object,
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labeltext: {
      type: String,
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    serverMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => ({
        start_date: "",
        end_date: "",
      }),
    },
  },

  methods: {
    valueChanged(newVal) {

      let convertedValue = {
        start_date: moment(String(newVal.start_date), "DD MMMM YYYY").format(
          "YYYY-MM-DD"
        ),
        end_date: moment(String(newVal.end_date), "DD MMMM YYYY").format(
          "YYYY-MM-DD"
        ),
      };

      this.$emit("update:modelValue", convertedValue);
    },
  },

};
</script>