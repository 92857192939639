import AuthRepository from "../../data/repository/auth-repository";
import SubscriptionRepository from "../../data/repository/subscription-repository";
import { logEvent } from "firebase/analytics";
import baseStore from "../base-store";

const initialPackage = {
  name: null,
  description: null,
  amount: null,
  currency: "MYR",
  frequency: null,
  frequency_num: null,
  start_after_num: null,
  start_after_type: null,
};

const initialSubscriber = {
  name: null,
  email: null,
  phone_no: null,
  package: {},
};

export default {
  namespaced: true,
  state: {
    ...baseStore.state,
    subscriber: initialSubscriber,
    listPackages: {},
    loadingDetail: false,
    package: initialPackage,
    packageFrequencies: [],
    packageStartAfterType: [],
    packages: [],
    loadingPackages: false,
    loadingChangePackage: false,
  },

  getters: {
    ...baseStore.getters,
    subscriber(state) {
      return state.subscriber;
    },
    list_packages(state) {
      return state.listPackages;
    },
    package(state) {
      return state.package;
    },
    packageStartAfterType(state) {
      return state.packageStartAfterType;
    },
    packageFrequencies(state) {
      return state.packageFrequencies
    },
    loadingDetail(state) {
      return state.loadingDetail;
    },
    packages(state) {
      return state.packages;
    },
    loadingPackages(state) {
      return state.loadingPackages;
    },
    loadingChangePackage(state) {
      return state.loadingChangePackage;
    },
  },

  mutations: {
    ...baseStore.mutations,
    setSubscriber(state, subscriber) {
      state.subscriber = subscriber;
    },
    setListPackages(state, listPackages) {
      state.listPackages = listPackages;
    },
    setPackage(state, packages = initialPackage) {
      return (state.package = packages);
    },
    initPackage(state, packages = initialPackage) {
      return (state.package = this.$lodash.clone(packages));
    },
    setPackageFrequencies(state, packages) {
      return (state.packageFrequencies = packages);
    },
    setPackageStartAfterType(state, packages) {
      return (state.packageStartAfterType = packages);
    },
    setLoadingDetail(state, loadingDetail) {
      state.loadingDetail = loadingDetail;
    },
    clearSubscriber(state) {
      state.subscriber = this.$lodash.clone(initialSubscriber);
    },
    setPackages(state, packages) {
      state.packages = packages;
    },
    setLoadingPackages(state, loadingPackages) {
      state.loadingPackages = loadingPackages;
    },
    setLoadingChangePackage(state, loadingChangePackage) {
      state.loadingChangePackage = loadingChangePackage;
    },
  },

  actions: {
    async retrievePackage(state, filters) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const packageIndexResult = await subscriptionRepository.indexPackage(
        business_id,
        filters
      );

      if (packageIndexResult.message != undefined) {
        logEvent(this.$analytics, "package-index", { result: "failed" });
        state.commit("setErrorMessage", packageIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "package-index", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationResponse", packageIndexResult);
      return packageIndexResult;
    },

    async createOrUpdatePackage(state, package_form) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(package_form).forEach((key) => {
        if (package_form[key] === "") {
          delete package_form[key];
        }
      });

      const packageResult = await subscriptionRepository.createOrUpdatePackage(
        business_id,
        package_form
      );

      const isCreate = package_form._id == null;

      if (packageResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "create-package" : "update-package",
          { result: "failed" }
        );
        state.commit("setErrorMessage", packageResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "create-package" : "update-package",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return packageResult;
    },

    async getPackageById(state, packageId) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const packageResult = await subscriptionRepository.getPackageById(
        packageId
      );

      if (packageResult.message != undefined) {
        logEvent(this.$analytics, "get-package", { result: "failed" });
        state.commit("setErrorMessage", packageResult);
        return;
      } else {
        logEvent(this.$analytics, "get-package", { result: "success" });
      }

      state.commit("setPackage", packageResult.data);
      state.commit("setLoading", false);
      return packageResult;
    },

    async retrieveFrequenciesNames(state) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const subscriptionResult =
        await subscriptionRepository.getPackageFrequencies(business_id);

      if (subscriptionResult.message != undefined) {
        logEvent(this.$analytics, "get-frequencies", { result: "failed" });
        state.commit("setErrorMessage", subscriptionResult);
        return;
      } else {
        logEvent(this.$analytics, "get-frequencies", { result: "success" });
      }

      const packageFrequencies = [];
      subscriptionResult.data?.forEach((package_frequency, index) => {
        packageFrequencies.push({
          id: package_frequency,
          name: package_frequency,
        });
      });

      state.commit("setLoading", false);
      state.commit("setPackageFrequencies", packageFrequencies);
      return subscriptionResult;
    },

    async retrieveStartAfterType(state) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const subscriptionResult =
        await subscriptionRepository.getPackageStartAfterType(business_id);

      if (subscriptionResult.message != undefined) {
        logEvent(this.$analytics, "get-start-after-type", { result: "failed" });
        state.commit("setErrorMessage", subscriptionResult);
        return;
      } else {
        logEvent(this.$analytics, "get-start-after-type", {
          result: "success",
        });
      }

      const packageStartAfterType = [];
      subscriptionResult.data?.forEach((package_start_after_type, index) => {
        packageStartAfterType.push({
          id: package_start_after_type,
          name: package_start_after_type,
        });
      });

      state.commit("setLoading", false);
      state.commit("setPackageStartAfterType", packageStartAfterType);
      return subscriptionResult;
    },

    async resetPackage(state) {
      state.commit("setPackage", {
        name: null,
        description: null,
        amount: null,
        currency: "MYR",
        frequency: null,
        frequency_num: null,
        start_after_num: null,
        start_after_type: null,
      });
    },

    async retrievePackagesByBusinessId(state) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const packagesResult =
        await subscriptionRepository.getPackagesByBusinessId(business_id);

      if (packagesResult.message != null) {
        state.commit("setErrorMessage", packagesResult);
        return null;
      }

      packagesResult.data?.map((listPackage) => {
        listPackage.id = listPackage._id;
        return listPackage;
      });

      state.commit("setLoading", false);
      state.commit("setListPackages", packagesResult.data);

      return packagesResult.data;
    },

    async retrieveSubscriber(state, params) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const subscriberIndexResult =
        await subscriptionRepository.indexSubscriber(business_id, params.package_id, params.filters);

      if (subscriberIndexResult.message != undefined) {
        logEvent(this.$analytics, "subscriber-index", { result: "failed" });
        state.commit("setErrorMessage", subscriberIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "subscriber-index", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationResponse", subscriberIndexResult);
      return subscriberIndexResult;
    },

    async createOrUpdateSubscriber(state, subscriber) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(subscriber).forEach((key) => {
        if (subscriber[key] === "") {
          delete subscriber[key];
        }
      });

      const subscriberResult =
        await subscriptionRepository.createOrUpdateSubscriber(
          business_id,
          subscriber
        );

      const isCreate = state.getters.subscriber.id == null;

      if (subscriberResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "create-subscriber" : "update-subscriber",
          { result: "failed" }
        );
        state.commit("setErrorMessage", subscriberResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "create-subscriber" : "update-subscriber",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return subscriberResult;
    },

    async getSubscriberPublic(state, subscriber_id) {
      const subscriptionRepository = new SubscriptionRepository();

      state.commit("setLoading", true);

      const subscriberResult = await subscriptionRepository.getSubscriberPublic(
        subscriber_id
      );

      if (subscriberResult.message != undefined) {
        logEvent(this.$analytics, "get-subscriber-public-error", {
          result: "failed",
        });
        state.commit("setErrorMessage", subscriberResult);
      } else {
        logEvent(this.$analytics, "get-subscriber-public-success", {
          result: "success",
        });
        state.commit("setSubscriber", subscriberResult.data);
      }

      state.commit("setLoading", false);

      return subscriberResult;
    },

    async retrieveSubscriberById(state, data) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoadingDetail", true);

      const subscriberResult = await subscriptionRepository.getSubscriberById(
        business_id,
        data
      );

      if (subscriberResult.message != undefined) {
        logEvent(this.$analytics, "get-subscriber-by-id", { result: "failed" });
        state.commit("setErrorMessage", subscriberResult);
      } else {
        logEvent(this.$analytics, "get-subscriber-by-id", {
          result: "success",
        });
        state.commit("setSubscriber", subscriberResult.data);
      }

      state.commit("setLoadingDetail", false);

      return subscriberResult;
    },

    async deletePackage(state, packages) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const packageResult = await subscriptionRepository.deletePackage(business_id, packages);

      if (packageResult.message != undefined) {
        logEvent(this.$analytics, "delete-package", { result: "failed" });
        state.commit("setErrorMessage", packageResult);
      } else {
        logEvent(this.$analytics, "delete-package", { result: "success" });
      }

      state.commit("setLoading", false);

      return packageResult;
    },

    async getPackageChangePackages(state, package_id) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoadingPackages", true);

      const packagesResult = await subscriptionRepository.getPackagesChangePackage(
        business_id,
        package_id
      );

      state.commit("setLoadingPackages", false);
      if (packagesResult.data == null) {
        logEvent(this.$analytics, "get-change-packages", { result: "failed" });
        state.commit("setErrorMessage", packagesResult);
        return null;
      }

      packagesResult.data?.map((listPackage) => {
        listPackage.id = listPackage._id;
        return listPackage;
      });
      logEvent(this.$analytics, "get-change-packages", {
        result: "success",
      });
      state.commit("setPackages", packagesResult.data);

      return packagesResult;
    },

    async changePackage(state, attribute) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoadingChangePackage", true);

      const changePackageResult = await subscriptionRepository.changePackage(
        business_id,
        attribute.package_id,
        attribute.subscriber_id,
        attribute.new_package_id
      );

      state.commit("setLoadingChangePackage", false);
      if (changePackageResult.data === null) {
        state.commit("setErrorMessage", changePackageResult);
        logEvent(this.$analytics, "change-package", { result: "failed" });
        return null;
      }

      logEvent(this.$analytics, "change-package", {
        result: "success",
      });
      return changePackageResult;
    },

    async cancelPackage(state, attribute) {
      const subscriptionRepository = new SubscriptionRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoadingChangePackage", true);

      const cancelPackageResult = await subscriptionRepository.cancelPackage(
        business_id,
        attribute.package_id,
        attribute.subscriber_id
      );

      state.commit("setLoadingChangePackage", false);
      if (cancelPackageResult.data === null) {
        state.commit("setErrorMessage", cancelPackageResult);
        logEvent(this.$analytics, "cancel-package", { result: "failed" });
        return null;
      }

      logEvent(this.$analytics, "cancel-package", {
        result: "success",
      });
      return cancelPackageResult;
    },
  },
};
