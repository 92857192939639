import AuthMiddleware from "./middleware/auth";
import GuestMiddleware from "./middleware/guest";
import AdminMiddleware from "./middleware/admin";
import OperationMiddleware from "./middleware/operation";
import MasterBusinessMiddleware from "./middleware/master-business";
import AccountMiddleware from "./middleware/account";
import SupportMiddleware from "./middleware/support";
import HasBusinessMiddleware from "./middleware/has-business";
import IncompleteBusinessMiddleware from "./middleware/incomplete-business";
import MainPageMiddleware from "./middleware/main-page";
import ApprovedBusinessMiddleware from "./middleware/approved-business";

export default [
  // --------------
  //   Main Route
  // --------------
  {
    path: "/",
    name: "/",
    meta: {
      middleware: [MainPageMiddleware],
    },
  },

  // --------------
  // Authentication
  // --------------
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/auth/login-page.vue"),
    meta: {
      title: "Login",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/pages/auth/logout-page.vue"),
    meta: {
      title: "Logout",
      authRequired: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/pages/auth/registration-page.vue"),
    meta: {
      title: "Register",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/pages/auth/forgot-password-page.vue"),
    meta: {
      title: "Forgot Password",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/pages/auth/reset-password-page.vue"),
    meta: {
      title: "Reset Password",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/reset-password-notification",
    name: "reset-password-notification",
    component: () =>
      import("@/views/pages/auth/reset-password-notification-page.vue"),
    meta: {
      title: "Reset Password Notification",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/success-reset-password",
    name: "success-reset-password",
    component: () =>
      import("@/views/pages/auth/success-reset-password-page.vue"),
    meta: {
      title: "Success Reset Password",
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: () => import("@/views/pages/auth/verify-email-page.vue"),
    meta: {
      title: "Verify Email",
      middleware: [AuthMiddleware],
    },
  },
  // --------------
  // Dashboard Page
  // --------------
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/pages/main/dashboard/dashboard-page.vue"),
    meta: {
      title: "Dashboard",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/customer",
    name: "customer",
    component: () => import("@/views/pages/main/view-page.vue"),
    meta: {
      title: "Customer",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/pages/main/view-page.vue"),
    meta: {
      title: "Products",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/report",
    name: "report",
    component: () => import("@/views/pages/main/view-page.vue"),
    meta: {
      title: "Report",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/link",
    name: "link",
    component: () => import("@/views/pages/main/view-page.vue"),
    meta: {
      title: "Link",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/tutorial",
    name: "tutorial",
    component: () => import("@/views/pages/main/view-page.vue"),
    meta: {
      title: "Tutorial",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/integration",
    name: "integration",
    redirect: {
      path: "/integration/api",
    },
    component: () =>
      import("@/views/pages/main/integration/container-page.vue"),
    meta: {
      title: "Integration",
      middleware: [
        AuthMiddleware,
        HasBusinessMiddleware,
        ApprovedBusinessMiddleware,
      ],
    },
    children: [
      {
        name: "integration-api",
        path: "api",
        meta: {
          title: "Api",
        },
        component: () => import("@/views/pages/main/integration/api-page.vue"),
      },
      {
        name: "integration-webhook",
        path: "webhook",
        meta: {
          title: "Webhook",
        },
        component: () =>
          import("@/views/pages/main/integration/webhook-page.vue"),
      },
      {
        name: "integration-webhook-details",
        path: "webhook/:business_id-:integration_id-:webhook_event_id",
        meta: {
          title: "Webhook Details",
        },
        component: () =>
          import("@/views/pages/main/integration/webhook-details-page.vue"),
      },
    ],
  },

  // -------------------
  //    Notifications
  // -------------------
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("@/views/pages/main/notification/index-page.vue"),
    meta: {
      title: "notifications",
      middleware: [AuthMiddleware],
    },
    children: [
      {
        path: ":notification_id",
        name: "notification-details",
        meta: {
          title: "Notification",
        },
        component: () =>
          import("@/views/pages/main/notification/details-page.vue"),
      },
    ],
  },

  // --------------
  //    Business
  // --------------
  {
    path: "/business",
    name: "business",
    redirect: {
      path: "/business/structure",
    },
    component: () => import("@/views/pages/main/business/business-page.vue"),
    meta: {
      title: "Business",
      middleware: [AuthMiddleware],
    },
    children: [
      {
        path: "structure",
        meta: {
          title: "Business Structure",
        },
        component: () =>
          import("@/views/pages/main/business/business-structure.vue"),
      },
      {
        path: "detail",
        meta: {
          title: "Business Details",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-detail.vue"),
      },
      {
        path: "representative",
        meta: {
          title: "Business Representative",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-representative.vue"),
      },
      {
        path: "owners",
        meta: {
          title: "Business Owners",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-owners.vue"),
      },
      {
        path: "support-details",
        meta: {
          title: "Support Details",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-support-details.vue"),
      },
      {
        path: "bank-details",
        meta: {
          title: "Bank Details",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-bank-details.vue"),
      },
      {
        path: "support-documents",
        meta: {
          title: "Support Documents",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-support-documents.vue"),
      },
      {
        path: "company-logo",
        meta: {
          title: "Company logo",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-company-logo.vue"),
      },
      {
        path: "summary",
        meta: {
          title: "Summary",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-summary.vue"),
      },
    ],
  },

  // ------------------------
  //    Business Settings
  // ------------------------
  {
    path: "/business/settings",
    name: "business.settings",
    redirect: {
      path: "/business/settings/company-logo",
    },
    component: () =>
      import("@/views/pages/main/business/business-settings-page.vue"),
    meta: {
      title: "Business Settings",
      middleware: [AuthMiddleware],
    },
    children: [
      {
        path: "payout-option",
        meta: {
          title: "Company logo",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/business/business-settings-payout-option.vue"
          ),
      },
      {
        path: "company-logo",
        meta: {
          title: "Company logo",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import("@/views/pages/main/business/business-company-logo.vue"),
      },
    ],
  },

  // --------------------------
  //    MASTER BUSINESS ROLE
  // --------------------------

  {
    path: "/master-business/list-business",
    name: "master-business.list-business",
    meta: {
      title: "List Business",
      middleware: [MasterBusinessMiddleware],
    },
    component: () =>
      import("@/views/pages/main/master-business/business/list-business.vue"),
  },

  {
    path: "/master-business/business",
    name: "master-business.business",
    redirect: {
      path: "/master-business/business/structure",
    },
    component: () =>
      import("@/views/pages/main/master-business/business/business-page.vue"),
    meta: {
      title: "Business",
      middleware: [AuthMiddleware],
    },
    children: [
      {
        path: "structure",
        meta: {
          title: "Business Structure",
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-structure.vue"
          ),
      },
      {
        path: "detail",
        meta: {
          title: "Business Details",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-detail.vue"
          ),
      },
      {
        path: "representative",
        meta: {
          title: "Business Representative",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-representative.vue"
          ),
      },
      {
        path: "owners",
        meta: {
          title: "Business Owners",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-owners.vue"
          ),
      },
      {
        path: "support-details",
        meta: {
          title: "Support Details",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-support-details.vue"
          ),
      },
      {
        path: "bank-details",
        meta: {
          title: "Bank Details",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-bank-details.vue"
          ),
      },
      {
        path: "company-logo",
        meta: {
          title: "Company logo",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-company-logo.vue"
          ),
      },
      {
        path: "summary",
        meta: {
          title: "Summary",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-summary.vue"
          ),
      },
      {
        path: "support-documents",
        meta: {
          title: "Support Documents",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-support-documents.vue"
          ),
      },
      {
        path: "payment-provider",
        meta: {
          title: "Payment Provider",
          middleware: [IncompleteBusinessMiddleware],
        },
        component: () =>
          import(
            "@/views/pages/main/master-business/business/business-payment-provider.vue"
          ),
      },
    ],
  },

  // --------------------
  // OPERATION ROLE
  // --------------------

  {
    path: "/business/index",
    name: "business.index",
    meta: {
      title: "List Business",
      middleware: [OperationMiddleware],
    },
    component: () =>
      import("@/views/pages/main/operation/business/business-index.vue"),
  },

  {
    path: "/business/:business_id/detail",
    name: "business-detail",
    meta: {
      title: "Business Detail",
      middleware: [OperationMiddleware],
    },
    redirect: (to) => ({
      name: "business.index.structure",
      params: { business_id: to.params.business_id },
    }),
    component: () =>
      import("@/views/pages/main/operation/business/business-page.vue"),
    children: [
      {
        path: "structure",
        name: "business.index.structure",
        meta: {
          title: "Business Structure",
        },
        component: () =>
          import(
            "@/views/pages/main/operation/business/business-structure.vue"
          ),
      },
      {
        path: "detail",
        name: "business.index.detail",
        meta: {
          title: "Business Details",
        },
        component: () =>
          import("@/views/pages/main/operation/business/business-detail.vue"),
      },
      {
        path: "representative",
        name: "business.index.representative",
        meta: {
          title: "Business Representative",
        },
        component: () =>
          import(
            "@/views/pages/main/operation/business/business-representative.vue"
          ),
      },
      {
        path: "owners",
        name: "business.index.owners",
        meta: {
          title: "Business Owners",
        },
        component: () =>
          import("@/views/pages/main/operation/business/business-owners.vue"),
      },
      {
        path: "support-details",
        name: "business.index.support-details",
        meta: {
          title: "Support Details",
        },
        component: () =>
          import(
            "@/views/pages/main/operation/business/business-support-details.vue"
          ),
      },
      {
        path: "payment-provider",
        name: "business.index.payment-provider",
        meta: {
          title: "Payment Provider",
        },
        component: () =>
          import(
            "@/views/pages/main/operation/business/business-payment-provider.vue"
          ),
      },
      {
        path: "bank-details",
        name: "business.index.bank-details",
        meta: {
          title: "Bank Details",
        },
        component: () =>
          import(
            "@/views/pages/main/operation/business/business-bank-details.vue"
          ),
      },
      {
        path: "support-documents",
        name: "business.index.support-documents",
        meta: {
          title: "Support Documents",
        },
        component: () =>
          import(
            "@/views/pages/main/operation/business/business-support-documents.vue"
          ),
      },
    ],
  },

  // --------------------
  //    ADMIN ROLE
  // --------------------

  {
    path: "/user/index",
    name: "user.index",
    meta: {
      title: "List User",
      middleware: [AdminMiddleware],
    },
    component: () => import("@/views/pages/main/admin/user/user-index.vue"),
  },
  {
    path: "/audit-trail/index",
    name: "audit_trail.index",
    meta: {
      title: "Audit Trails",
      middleware: [AdminMiddleware],
    },
    component: () =>
      import("@/views/pages/main/admin/audit/audit-trails-index.vue"),
  },
  {
    path: "/cron/index",
    name: "cron.index",
    meta: {
      title: "Cron Jobs",
      middleware: [AdminMiddleware],
    },
    component: () =>
      import("@/views/pages/main/admin/cron/cron-job-index.vue"),
  },

  // -------------------
  //    ACCOUNT ROLE
  // -------------------
  {
    path: "/account/payout",
    name: "account.payout",
    component: () =>
      import("@/views/pages/main/account/payout/container-page.vue"),
    redirect: {
      path: "/account/payout/index",
    },
    meta: {
      middleware: [AccountMiddleware],
    },
    children: [
      {
        path: "index",
        name: "account.payout.index",
        meta: {
          title: "List Payout",
        },
        component: () =>
          import("@/views/pages/main/account/payout/payout-index.vue"),
      },
      {
        path: "transaction/payment-link/:business_id-:settlement_id/index",
        name: "account.payout.transaction.payment-link",
        component: () =>
          import(
            "@/views/pages/main/wallet/transaction/settlement-index-page.vue"
          ),
        meta: {
          title: "Processed Payment Link List",
        },
      },
    ],
  },

  {
    path:"/account/transaction/list",
    name:"account.transaction.list",
    meta:{
      middleware: [AccountMiddleware],
    },
    component:()=>
      import(
        "@/views/pages/main/account/transaction/transaction-list.vue"
      ),
  },

  {
    path:"/account/report/index",
    name:"account.report.index",
    meta: {
      middleware: [AccountMiddleware],
    },
    component:() =>
      import(
        "@/views/pages/main/account/report/report-index.vue"
      ),
  },

  // -------------------
  //    SUPPORT ROLE
  // -------------------

  {
    path: "/support/payment/link/index",
    name: "support.payment.link.index",
    meta: {
      title: "Payment Links",
      middleware: [SupportMiddleware],
    },
    component: () =>
      import("@/views/pages/main/support/payment/payment-link-index.vue"),
  },

  {
    path: "/support/balance",
    name: "support.balance",
    component: () =>
      import("@/views/pages/main/support/balance/container-page.vue"),
    redirect: {
      path: "/support/balance/payout/index",
    },
    meta: {
      middleware: [SupportMiddleware],
    },
    children: [
      {
        path: "transaction/index",
        name: "support.balance.transaction.index",
        component: () =>
          import(
            "@/views/pages/main/support/balance/transaction/index-page.vue"
          ),
        meta: {
          title: "Transaction List",
        },
      },
      {
        path: "transaction/payment-link/:business_id-:settlement_id/index",
        name: "support.balance.transaction.payment-link",
        component: () =>
          import(
            "@/views/pages/main/wallet/transaction/settlement-index-page.vue"
          ),
        meta: {
          title: "Processed Payment Link List",
        },
      },
      {
        path: "payout/index",
        name: "support.balance.payout.index",
        component: () =>
          import("@/views/pages/main/support/balance/payout/index-page.vue"),
        meta: {
          title: "Payout List",
        },
      },
    ],
  },

  // --------------
  //    Payment
  // --------------
  {
    path: "/payment/link",
    name: "payment-link",
    component: () =>
      import("@/views/pages/main/payment/payment-link/container-page.vue"),
    redirect: {
      path: "/payment/link/index",
    },
    meta: {
      title: "Payment",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "index",
        name: "payment-link.index",
        component: () =>
          import("@/views/pages/main/payment/payment-link/index-page.vue"),
        meta: {
          title: "Payment",
        },
      },
      {
        path: "create",
        name: "payment-link.create",
        component: () =>
          import("@/views/pages/main/payment/payment-link/create-page.vue"),
        meta: {
          title: "Payment",
        },
      },
    ],
  },
  {
    path: "/payment/link/pay",
    name: "payment-link.pay",
    component: () =>
      import("@/views/pages/main/payment/payment-link/payment-page.vue"),
    meta: {
      title: "Payment",
      middleware: [],
    },
  },
  {
    path: "/payment/thank-you",
    name: "payment-thank-you",
    component: () =>
      import("@/views/pages/main/payment/payment-thank-you-page.vue"),
    meta: {
      title: "Payment Thank you",
    },
  },

  // --------------
  //    Open Payment
  // --------------
  {
    path: "/payment/open-link",
    name: "payment-open-link",
    component: () =>
      import("@/views/pages/main/payment/open-payment-link/container-page.vue"),
    redirect: {
      path: "/payment/open-link/index",
    },
    meta: {
      title: "Open Payment",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "index",
        name: "payment-open-link.index",
        component: () =>
          import("@/views/pages/main/payment/open-payment-link/index-page.vue"),
        meta: {
          title: "Open Payment Links",
        },
      },
      {
        path: "create",
        name: "payment-open-link.create",
        component: () =>
          import(
            "@/views/pages/main/payment/open-payment-link/create-page.vue"
          ),
        meta: {
          title: "Create Open Payment Link",
        },
      },
      {
        path: ":open_payment_link_id/payment-links",
        name: "payment-open-link.index.payment-links",
        component: () =>
          import(
            "@/views/pages/main/payment/open-payment-link/payment-links-page.vue"
          ),
        meta: {
          title: "Open Payments Link's Payment Link",
        },
      },
    ],
  },
  {
    path: "/payment/open-link/edit/:id",
    name: "payment-open-link.edit",
    component: () =>
      import("@/views/pages/main/payment/open-payment-link/edit-page.vue"),
    meta: {
      title: "Edit Open Payment",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/payment/open-link/pay",
    name: "open-payment-link.pay",
    component: () =>
      import("@/views/pages/main/payment/open-payment-link/payment-page.vue"),
    meta: {
      title: "Open Payment",
      middleware: [],
    },
  },

  // --------------
  //    PAYMENT SETTING
  // --------------
  {
    path: "/payment-settings/index",
    name: "payment-settings",
    component: () =>
      import("@/views/pages/main/payment/settings/index-page.vue"),
    meta: {
      title: "Payment Setting",
    },
  },

  // --------------------
  //    Subscription
  // --------------------
  {
    path: "/subscription/redirect",
    name: "subscription-redirect",
    component: () =>
      import("@/views/pages/main/subscription/subscription-redirect-page.vue"),
    meta: {
      title: "Subscription Redirect",
    },
  },
  {
    path: "/subscription/activation/:subscriber_id",
    name: "subscription-activation",
    component: () =>
      import(
        "@/views/pages/main/subscription/subscription-activation-page.vue"
      ),
    meta: {
      title: "Activation subscribe",
    },
  },
  // --------------------------
  //          Wallet
  // --------------------------
  {
    path: "/balance",
    name: "balance",
    component: () => import("@/views/pages/main/wallet/container-page.vue"),
    redirect: {
      path: "/balance/overview/index",
    },
    meta: {
      title: "Transaction",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "transaction/index",
        name: "balance.transaction.index",
        component: () =>
          import("@/views/pages/main/wallet/transaction/index-page.vue"),
        meta: {
          title: "Transaction List",
        },
      },
      {
        path: "/transaction/payment-link/:business_id-:settlement_id/index",
        name: "balance.transaction.payment-link",
        component: () =>
          import(
            "@/views/pages/main/wallet/transaction/settlement-index-page.vue"
          ),
        meta: {
          title: "Processed Payment Link List",
        },
      },
      {
        path: "payout/index",
        name: "balance.payout.index",
        component: () =>
          import("@/views/pages/main/wallet/payout/index-page.vue"),
        meta: {
          title: "Payout List",
        },
      },
      {
        path: "overview/index",
        name: "balance.overview.index",
        component: () =>
          import("@/views/pages/main/wallet/overview/index-page.vue"),
        meta: {
          title: "Balance Overview",
        },
      },
    ],
  },

  // --------------------------
  //        subscription
  // --------------------------
  {
    path: "/subscription",
    name: "subscription",
    component: () =>
      import("@/views/pages/main/subscription/container-page.vue"),
    redirect: {
      path: "/subscription/package/index",
    },
    meta: {
      title: "subscriber",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "subscriber/create",
        name: "subscription.subscriber.create",
        component: () =>
          import("@/views/pages/main/subscription/subscriber/create-page.vue"),
        meta: {
          title: "Create Subscriber",
        },
      },
      {
        path: "subscriber-list/:package_name/:package_id",
        name: "subscription.subscriber.list",
        component: () =>
          import("@/views/pages/main/subscription/subscriber/index-page.vue"),
        meta: {
          title: "Create Subscriber",
        },
      },
      {
        path: "package/index",
        name: "subscription.package.index",
        component: () =>
          import("@/views/pages/main/subscription/package/index-page.vue"),
        meta: {
          title: "Package List",
        },
      },
    ],
  },
  // --------------------------
  //        customer
  // --------------------------
  {
    path: "/customer",
    name: "customer",
    component: () => import("@/views/pages/main/customer/container-page.vue"),
    redirect: {
      path: "/customer/customer-list/index",
    },
    meta: {
      title: "customer",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "customer-list/index",
        name: "customer.customer-list.index",
        component: () =>
          import("@/views/pages/main/customer/customer-list/index-page.vue"),
        meta: {
          title: "Customer List",
        },
      },
    ],
  },

  // -------------------
  //      PRODUCT
  // -------------------
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/pages/main/product/container-page.vue"),
    redirect: {
      path: "product/index",
    },
    meta: {
      title: "Product",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "index",
        name: "product.index",
        component: () => import("@/views/pages/main/product/index-page.vue"),
        meta: {
          title: "Product List",
        },
      },
    ],
  },
  {
    path: "/invoice/pay",
    name: "invoice.pay",
    component: () => import("@/views/pages/main/invoice/payment-page.vue"),
    meta: {
      title: "Invoice Payment",
      middleware: [],
    },
  },

  // -------------
  //      TAX
  // -------------
  {
    path: "/tax",
    name: "tax",
    component: () => import("@/views/pages/main/tax/container-page.vue"),
    redirect: {
      path: "tax/index",
    },
    meta: {
      title: "tax",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "index",
        name: "tax.index",
        component: () => import("@/views/pages/main/tax/index-page.vue"),
        meta: {
          title: "Tax List",
        },
      },
    ],
  },

  // -------------------
  //      INVOICE
  // -------------------
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("@/views/pages/main/invoice/container-page.vue"),
    redirect: {
      path: "/invoice/index",
    },
    meta: {
      title: "Invoice",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
    children: [
      {
        path: "index",
        name: "invoice.index",
        component: () => import("@/views/pages/main/invoice/index-page.vue"),
        meta: {
          title: "Invoice List",
        },
      },
      {
        path: "report/aging",
        name: "invoice.report.aging",
        component: () =>
          import("@/views/pages/main/invoice/report/aging.vue"),
        meta: {
          title: "Aging Report",
        },
      },
    ],
  },
  {
    path: "/invoice/create",
    name: "invoice.create",
    component: () => import("@/views/pages/main/invoice/create-page.vue"),
    meta: {
      title: "Create New Invoice",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
  },
  {
    path: "/invoice/edit/:id",
    name: "invoice.edit",
    component: () => import("@/views/pages/main/invoice/create-page.vue"),
    meta: {
      title: "Edit Invoice",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
  },
  {
    path: "/invoice/duplicate/:id",
    name: "invoice.duplicate",
    component: () => import("@/views/pages/main/invoice/create-page.vue"),
    meta: {
      title: "Duplicate Invoice",
      middleware: [AuthMiddleware, HasBusinessMiddleware],
    },
  },

  // --------------
  //   Error Page
  // --------------
  {
    path: "/not-found",
    name: "not-found",
    component: () => import("@/views/pages/error/not-found-page.vue"),
    meta: {
      title: "Page not Found",
    },
  },
  {
    path: "/invalid",
    name: "invalid",
    component: () => import("@/views/pages/error/invalid-page.vue"),
    meta: {
      title: "Invalid Page",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/not-found",
  },
];
