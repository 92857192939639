// Install
import { install } from './install';


const VueTawkMessenger = {
	version : 'v1',
	install
};


export default VueTawkMessenger;