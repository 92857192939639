import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";
import { logEvent } from "firebase/analytics";

export default class WalletRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async index(business_id, queryParams) {
    const response = await client.get(
      "/wallets/businesses/" + business_id + "/wallets" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async indexMasterBusiness(business_id, filtersUrl) {
    const response = await client.get(
      "/wallets/master-businesses/" + business_id + "/wallets",
      this.getToken(),
      filtersUrl
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async settlementIndex(business_id, settlement_id, queryParams) {
    const response = await client.get(
      "/wallets/businesses/" + business_id + "/settlements/" + settlement_id + "/payment-links" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async settlementIndexMasterBusiness(business_id, settlement_id, filters = {}) {
    const response = await client.get(
      "/wallets/master-businesses/" + business_id + "/settlements/" + settlement_id + "/payment-links",
      this.getToken(),
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }


  async walletBalance(business_id) {
    const response = await client.get(
      "/wallets/businesses/" + business_id + "/wallets-balance",
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async accountBalance(business_id, currency) {
    const response = await client.get(
      `/wallets/businesses/${business_id}/currencies/${currency}/account-balance`,
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async walletBalanceMasterBusiness(business_id) {
    const response = await client.get(
      "/wallets/master-businesses/" + business_id + "/wallets-balance",
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async downloadSettlementCSV(business_id, settlement_id, queryParams) {
    const response = await client.get(
      "/wallets/businesses/" + business_id + "/settlements/" + settlement_id + "/export-payment-link" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }
    return this.successResponse(response.data);
  }

  async payoutIndex(business_id, queryParams) {
    const response = await client.get(
      "/wallets/businesses/" + business_id + "/payouts" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async payoutIndexMasterBusiness(business_id, queryParams) {
    const response = await client.get(
      "/wallets/master-businesses/" + business_id + "/payouts" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async payoutRequest(business_id, data = {}) {
    const response = await client.post(
      "/wallets/businesses/" + business_id + "/payouts",
      data,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getListPayouts(queryParams) {
    const response = await client.get(
      "wallets/payouts" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async getListPayoutsExports(queryParams) {
    const response = await client.get(
      "wallets/payouts/exports" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async downloadListPayouts(params = {}) {
    const response = await client.get(
      "wallets/download-payouts",
      this.getToken(),
      params
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async downloadPdf(queryParams) {
    const response = await client.get(
      "wallets/payouts/download-pdf" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async updatePayoutStatus(payout_id, status, reference_id, reason = null) {
    const payload = (reason == null) ? {
      status: status,
      reference_id: reference_id
    } : {
      status: status,
      reason: reason,
      reference_id: reference_id
    };
    const response = await client.put(
      "wallets/payouts/" + payout_id + "/update-status",
      payload,
      this.getToken(),
    )

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async updatePayoutStatusWithDocuments(payout_id, payload) {
    const response = await client.put(
      "wallets/payouts/" + payout_id + "/update-status-with-documents",
      payload,
      this.getToken(),
    )

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async updatePayoutResolve(business_id, payout_id) {

    const response = await client.put(
      "/wallets/businesses/" + business_id + "/payouts/" + payout_id + "/status-resolve",
      null,
      this.getToken(),
    )

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async bulkUpdatePayoutStatus(payout_ids) {
    const response = await client.put(
      "wallets/payouts/bulk-update-status",
      payout_ids,
      this.getToken(),
    )

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async updateRoundOffAmountPayout(payout_id) {
    const response = await client.post(
      "wallets/payouts/"+ payout_id +"/update-round-off",
      [],
      this.getToken(),
    )

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async cancelRoundOffAmountPayout(payout_id) {
    const response = await client.post(
      "wallets/payouts/"+ payout_id +"/cancel-round-off",
      [],
      this.getToken(),
    )

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getTransactionForSupport(queryParams) {
    const response = await client.get(
      "wallets/transactions" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async getListPayoutsForAccount(queryParams) {
    const response = await client.get(
      "wallets/payouts" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async getPayoutForSupport(queryParams) {
    const response = await client.get(
      "wallets/payouts" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async balanceOverview(business_id, currency) {
    const response = await client.get(
      "/wallets/balance/" + business_id + "/currencies/" + currency + "/overview",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

}
