import AuthRepository from "../../data/repository/auth-repository";
import CustomerRepository from "../../data/repository/customer-repository";
import { logEvent } from "firebase/analytics";
import baseStore from "../base-store";

const initialCustomer = {
  name: null,
  email: null,
  phone_no: null,
  invoice_prefix: null,
  invoice_sequence: null,
  billing_address_1: null,
  billing_address_2: null,
  billing_postcode: null,
  billing_city: null,
  billing_state_id: null,
  billing_state: {},
  billing_country_id: 160,
  billing_country: {
    id: 160,
    name: "Malaysia",
    icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
  },
  use_billing_address: false,
  shipping_address_1: null,
  shipping_address_2: null,
  shipping_postcode: null,
  shipping_city: null,
  shipping_state_id: null,
  shipping_state: {},
  shipping_country_id: 160,
  shipping_country: {
    id: 160,
    name: "Malaysia",
    icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
  },
};

export default {
  namespaced: true,
  state: {
    ...baseStore.state,

    loadingDetail: false,
    customer: { ...initialCustomer },
  },

  getters: {
    ...baseStore.getters,

    customer(state) {
      return state.customer;
    },

    customerAddress(state) {
      return (mode = 'billing') => {
        let address = "";
        if (state.customer[`${mode}_address_1`]) {
          address = `${state.customer[`${mode}_address_1`]},`;
          if (state.customer[`${mode}_address_2`])
            address += `\n${state.customer[`${mode}_address_2`]},`;
          address += '\n'
          if (state.customer[`${mode}_city`])
            address += `${state.customer[`${mode}_city`]},`;
          if (state.customer[`${mode}_state`].name)
            address += ` ${state.customer[`${mode}_state`].name}`;
          if (state.customer[`${mode}_postcode`])
            address += ` ${state.customer[`${mode}_postcode`]}`;
        }
        return address;
      }
    },

    list_customer(state) {
      return state.listCustomer;
    },

    loadingDetail(state) {
      return state.loadingDetail;
    },
  },

  mutations: {
    ...baseStore.mutations,

    setCustomer(state, customer= initialCustomer) {
      return (state.customer = customer);
    },

    initCustomer(state, customer= initialCustomer) {
      return (state.customer = this.$lodash.cloneDeep(customer));
    },

    setListCustomer(state, listCustomer) {
      state.listCustomer = listCustomer;
    },

    setLoadingDetail(state, loadingDetail) {
      state.loadingDetail = loadingDetail;
    },
  },

  actions: {
    async retrieveCustomer(state, filters) {
      const customerRepository = new CustomerRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const customerIndexResult = await customerRepository.indexCustomer(
        business_id,
        filters
      );

      if (customerIndexResult.message != undefined) {
        logEvent(this.$analytics, "customer-index", { result: "failed" });
        state.commit("setErrorMessage", customerIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "customer-index", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationResponse", customerIndexResult);
      return customerIndexResult;
    },

    async createOrUpdateCustomer(state, customer_form) {
      const customerRepository = new CustomerRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;
      
      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(customer_form).forEach((key) => {
        if (customer_form[key] === "") {
          delete customer_form[key];
        }
      });

      const customerResult = await customerRepository.createOrUpdateCustomer(
        business_id,
        customer_form
      );

      const isCreate = customer_form._id == null;

      if (customerResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "create-customer" : "update-customer",
          { result: "failed" }
        );
        state.commit("setErrorMessage", customerResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "create-customer" : "update-customer",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return customerResult;
    },

    async getCustomerById(state, customer_id) {
      const customerRepository = new CustomerRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const customerResult = await customerRepository.getCustomerById(
        business_id,
        customer_id
      );

      if (customerResult.message != undefined) {
        logEvent(this.$analytics, "get-customer", { result: "failed" });
        state.commit("setErrorMessage", customerResult);
        return;
      } else {
        logEvent(this.$analytics, "get-customer", { result: "success" });
      }

      state.commit("setCustomer", customerResult.data);
      state.commit("setLoading", false);
      return customerResult;
    },

    async updateLocalCustomer(state, customer) {
      state.commit('setCustomer', customer);
    },

    async resetCustomerWithInitialCustomer(state) {
      state.commit("initCustomer");
    },

    async resetCustomer(state) {
      state.commit("setCustomer", {
        name: null,
        email: null,
        phone_no: null,
        invoice_prefix: null,
        invoice_sequence: null,
        billing_address_1: null,
        billing_address_2: null,
        billing_postcode: null,
        billing_city: null,
        billing_state_id: null,
        billing_country_id: null,
        shipping_address_1: null,
        shipping_address_2: null,
        shipping_postcode: null,
        shipping_city: null,
        shipping_state_id: null,
        shipping_country_id: null,
      });
    },

    async deleteCustomer(state, customer) {
      const customerRepository = new CustomerRepository();
      const authRepository = new AuthRepository();
      
      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      const customerResult = await customerRepository.deleteCustomer(business_id, customer);

      if (customerResult.message != undefined) {
        logEvent(this.$analytics, "delete-customer", { result: "failed" });
        state.commit("setErrorMessage", customerResult);
      } else {
        logEvent(this.$analytics, "delete-customer", { result: "success" });
      }

      state.commit("setLoading", false);

      return customerResult;
    },

    // async retrievePackagesByBusinessId(state) {
    //   const subscriptionRepository = new SubscriptionRepository();
    //   const authRepository = new AuthRepository();

    //   const business_id = authRepository.getSelectedRole()?.business_id;

    //   if (business_id == null) {
    //     return;
    //   }

    //   state.commit("setLoading", true);
    //   const packagesResult = await subscriptionRepository.getPackagesByBusinessId(
    //     business_id,
    //   );

    //   if (packagesResult.message != null) {
    //     state.commit("setErrorMessage", packagesResult);
    //     return null;
    //   }

    //   packagesResult.data?.map((listPackage) => {
    //     listPackage.id = listPackage._id
    //     return listPackage
    //   });

    //   state.commit("setLoading", false);
    //   state.commit("setListPackages", packagesResult.data);

    //   return packagesResult.data;
    // },
  },
};
