<template>
  <div>
    <span
      v-if="status == 'paid' || status == 'approved'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full bg-success-badge text-green-900 text-xs"
    >
      <app-icon name="CheckIcon" class="w-4" />
    </span>

    <span
      v-if="status == 'error'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full bg-error-badge text-red-900 text-xs"
    >
      <app-icon name="XIcon" class="w-4" />
    </span>

    <span
      v-if="status == 'receipt'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full grayscale"
    >
      <app-icon name="CheckIcon" class="w-4" />
    </span>

    <span
      v-if="status == 'unknown'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full grayscale"
    >
      <app-icon name="StatusOfflineIcon" class="w-4" />
    </span>

    <span
      v-if="status == 'requested'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full grayscale"
    >
      <app-icon name="ClipboardCheckIcon" class="w-4" />
    </span>
    <span
      v-else-if="status == 'processing'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full text-blue-900 text-xs"
    >
      <app-icon name="DotsHorizontalIcon" class="w-4" />
    </span>
    <span
      v-else-if="status == 'pending'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full text-yellow-900 text-xs"
    >
      <app-icon name="ExclamationCircleIcon" class="w-4" />
    </span>

    <span
      v-if="status == 'returned'"
      class="flex place-items-center space-x-1 p-1.5 rounded-full grayscale"
    >
      <app-icon name="ReplyIcon" class="w-4" />
    </span>

    <span
      v-else-if="status == 'created'"
      class="flex place-items-center space-x-1 p-1"
    >
      <app-icon-small-logo class="mb-1.5" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "created",
    },
  },
};
</script>
