import baseStore from "../base-store";
import MasterBusinessRepository from "../../data/repository/master-business-repository";
import BusinessDetailRepository from "../../data/repository/business-detail-repository";
import BusinessBankRepository from "../../data/repository/business-bank-repository";
import AuthRepository from "../../data/repository/auth-repository";
import { logEvent } from "firebase/analytics";
import BusinessSupportDetailsRepository from "../../data/repository/business-support-details-repository";
import BusinessRepresentativeRepository from "../../data/repository/business-representative-repository";
import BusinessSupportDocumentsRepository from "../../data/repository/business-support-documents-repository";
import MEDIA_CATEGORY from "@/utils/const/media_category";

const initialBusiness = {
  id: null,
  business_status_id: null,
  country: {
    id: 160,
    name: "Malaysia",
    icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
  },
  comment: null,
  detail: {
    id: null,
    business_id: null,
    company_name: null,
    company_reg_no: null,
    business_activity: null,
    address_1: null,
    address_2: null,
    postcode: null,
    city: null,
    state: null,
    state_id: null,
    phone_no: null,
    industry: null,
    industry_id: null,
    website: null,
    description: null,
  },
  representative: {
    full_name: null,
    email: null,
    date_of_birth: null,
    address_1: null,
    address_2: null,
    postcode: null,
    city: null,
    country_id: null,
    country: {
      id: 160,
      name: "Malaysia",
      icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
    },
    state_id: null,
    phone_no: null,
    identification_type: "MyKad",
    identification: null,
  },
  status: null,
  owners: null,
  support_detail: {
    statement_descriptor: null,
    short_descriptor: null,
    support_tel_no: null,
    support_email: null,
  },
  bank: {
    account_no: null,
    account_holder: null,
  },
};

export default {
  namespaced: true,
  state: {
    // variable
    ...baseStore.state,
    business: initialBusiness,
    list_business: [],
    all_business: [],
    all_business_for_select: [],
    support_documents: [],
    type_support_documents: [],
    validate_support_documents: false,
    selectBusiness: null,
  },

  getters: {
    // method untuk di akses
    ...baseStore.getters,

    business(state) {
      if (state.business == null) return state.business;

      if (state.business.detail == null) {
        state.business.detail = initialBusiness.detail;
      }
      if (state.business.representative == null) {
        state.business.representative = initialBusiness.representative;
      }
      if (state.business.support_detail == null) {
        state.business.support_detail = initialBusiness.support_detail;
      }
      if (state.business.bank == null) {
        state.business.bank = initialBusiness.bank;
      }
      return state.business;
    },

    list_business(state) {
      return state.list_business;
    },

    all_business(state) {
      return state.all_business;
    },

    all_business_for_select(state) {
      return state.all_business_for_select;
    },

    logo(state) {
      return state.business.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.LOGO
      )?.original_url;
    },

    icon(state) {
      return state.business.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.ICON
      )?.original_url;
    },

    support_documents(state) {
      return state.support_documents;
    },

    validate_support_documents(state) {
      return state.validate_support_documents;
    },

    type_support_documents(state) {
      return state.type_support_documents;
    },

    selectBusiness(state) {
      return state.selectBusiness;
    },
  },

  mutations: {
    // method untuk ubah value variable di state
    ...baseStore.mutations,

    setBusiness(state, business) {
      state.business = business;
    },

    setBusinessDetail(state, detail) {
      state.business.detail = detail;
    },

    setBusinessRepresentative(state, representative) {
      state.business.representative = representative;
    },

    setBusinessOwners(state, owners) {
      state.business.owners = owners;
    },

    setBusinessBank(state, bank) {
      if (bank.bank) {
        bank.bank.name = bank.bank.full_name;
        bank.bank.icon = bank.bank.logo_url;
      }
      state.business.bank = bank;
    },

    setBusinessSupportDetails(state, support_detail) {
      state.business.support_detail = support_detail;
    },

    clearBusiness(state) {
      state.business = initialBusiness;
    },

    setListBusiness(state, list_business) {
      state.list_business = list_business;
    },

    setAllBusiness(state, all_business) {
      state.all_business = all_business;
    },
    setAllBusinessForSelect(state, all_business_for_select) {
      state.all_business_for_select = all_business_for_select;
    },
    setSupportDocuments(state, support_documents) {
      state.support_documents = support_documents;
    },
    setValidateSupportDocuments(state, validate_support_documents) {
      state.validate_support_documents = validate_support_documents;
    },
    setTypeSupportDocuments(state, type_support_documents) {
      state.type_support_documents = type_support_documents;
    },
    setSelectBusiness(state, selectBusiness) {
      state.selectBusiness = selectBusiness;
    },
  },

  actions: {
    // method untuk long process / asynchronous

    async createOrUpdateMasterBusiness(state, business) {
      const masterBusinessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      state.commit("setLoading", true);

      const businessResult = await masterBusinessRepository.createOrUpdateMasterBusiness(masterBusinessId, business);

      if (businessResult.message != null) {
        logEvent(
          this.$analytics,
          business.id == null ? "create-master-business" : "update-master-business",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(
          this.$analytics,
          businessResult.data.id == null
            ? "create-master-business"
            : "update-master-business",
          { result: "success" }
        );

        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);
      return businessResult.data;
    },

    async createOrUpdateMasterBusinessDetail(state, detail) {
      detail.business_id = detail.business_id
        ? detail.business_id
        : state.getters["business"].id;

      const businessDetailRepository = new BusinessDetailRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessDetailResult = await businessDetailRepository.createOrUpdateMasterBusiness(
        masterBusinessId,
        detail
      );

      if (businessDetailResult.message != null) {
        logEvent(
          this.$analytics,
          detail.id == null
            ? "create-master-business-detail"
            : "update-master-business-detail",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessDetailResult);
        return;
      }

      logEvent(
        this.$analytics,
        detail.id == null ? "create-master-business-detail" : "update-master-business-detail",
        { result: "success" }
      );

      state.commit("setBusinessDetail", businessDetailResult.data);
      state.commit("setLoading", false);
      return businessDetailResult.data;
    },

    async createOrUpdateMasterBusinessRepresentative(state, representative) {
      representative.business_id = state.getters["business"].id;

      const businessRepresentativeRepository = new BusinessRepresentativeRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessRepresentativeResult = await businessRepresentativeRepository.createOrUpdateMasterBusiness(masterBusinessId,representative);

      if (businessRepresentativeResult.message != null) {
        logEvent(
          this.$analytics,
          representative.id == null
            ? "create-master-business-representative"
            : "update-master-business-representative",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessRepresentativeResult);
        return;
      }
      logEvent(
        this.$analytics,
        representative.id == null
          ? "create-master-business-representative"
          : "update-master-business-representative",
        { result: "success" }
      );

      state.commit(
        "setBusinessRepresentative",
        businessRepresentativeResult.data
      );
      state.commit("setLoading", false);

      return businessRepresentativeResult.data;
    },

    async createOrUpdateMasterBusinessOwner(state, business_owner) {
      const masterBusinessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      if (masterBusinessId == null) {
        return;
      }

      state.commit("setLoading", true);

      const businessOwnerResult = await masterBusinessRepository.createOrUpdateMasterBusinessOwner(
        masterBusinessId,
        business_owner
      );

      if (businessOwnerResult.message != undefined) {
        logEvent(
          this.$analytics,
          businessOwnerResult.id == null
            ? "create-master-business-owner"
            : "update-master-business-owner",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessOwnerResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          businessOwnerResult.id == null
            ? "create-master-business-owner"
            : "update-master-business-owner",
          { result: "success" }
        );
      }

      state.commit("setLoading", false);

      return businessOwnerResult;
    },

    async deleteMasterBusinessOwner(state, businessOwner) {
      const masterBusinessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      state.commit("setLoading", true);

      const businessOwnerResult = await masterBusinessRepository.deleteMasterBusinessOwner(
        masterBusinessId,
        businessOwner
      );

      if (businessOwnerResult.message != null) {
        logEvent(this.$analytics, "delete-master-business-owner", {
          result: "failed",
        });
        state.commit("setErrorMessage", businessOwnerResult);
      } else {
        logEvent(this.$analytics, "delete-master-business-owner", {
          result: "success",
        });
      }

      state.commit("setLoading", false);

      return businessOwnerResult;
    },

    async createOrUpdateMasterBusinessSupportDetails(state, support_detail) {
      support_detail.business_id = support_detail.business_id
        ? support_detail.business_id
        : state.getters["business"].id;

      const businessSupportDetailsRepository = new BusinessSupportDetailsRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessSupportDetailsResult = await businessSupportDetailsRepository.createOrUpdateMasterBusiness(
        masterBusinessId,
        support_detail
      );

      if (businessSupportDetailsResult.message != null) {
        logEvent(
          this.$analytics,
          support_detail.id == null
            ? "create-master-business-support-details"
            : "update-master-business-support-details",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessSupportDetailsResult);
        return;
      }

      logEvent(
        this.$analytics,
        support_detail.id == null
          ? "create-master-business-support-details"
          : "update-master-business-support-details",
        { result: "success" }
      );

      state.commit(
        "setBusinessSupportDetails",
        businessSupportDetailsResult.data
      );
      state.commit("setLoading", false);

      return businessSupportDetailsResult.data;
    },

    async createOrUpdateMasterBusinessBank(state, bank) {
      bank.business_id = state.getters["business"].id;

      const businessBankRepository = new BusinessBankRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessBankResult = await businessBankRepository.createOrUpdateMasterBusiness(
        masterBusinessId,
        bank
      );

      if (businessBankResult.message != null) {
        logEvent(
          this.$analytics,
          bank.id == null ? "create-master-business-bank" : "update-master-business-bank",
          { result: "failed" }
        );
        state.commit("setErrorMessage", businessBankResult);
        return;
      }
      logEvent(
        this.$analytics,
        bank.id == null ? "create-master-business-bank" : "update-master-business-bank",
        { result: "success" }
      );

      state.commit("setBusinessBank", businessBankResult.data);
      state.commit("setLoading", false);
      return businessBankResult.data;
    },

    async getBusinessSupportDocuments(state, businessId) {
      const businessSupportDocumentsRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      if (masterBusinessId == null) {
        return;
      }

      const businessSupportDocumentResult = await businessSupportDocumentsRepository.indexMasterBusiness(masterBusinessId, businessId);

      if (businessSupportDocumentResult.message != null) {
        logEvent(this.$analytics, "index-business-support-document-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessSupportDocumentResult);
      } else {
        logEvent(this.$analytics, "index-business-support-document-master-business", { result: "success" });
        state.commit("setSupportDocuments", businessSupportDocumentResult.data);
      }

      state.commit("setLoading", false);

      return businessSupportDocumentResult.data;
    },

    async deleteBusinessSupportDocument(state, data) {
      const businessSupportDocumentsRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      if (masterBusinessId == null) {
        return;
      }

      const businessSupportDocumentResult = await businessSupportDocumentsRepository.deleteMasterBusiness(
        masterBusinessId,
        data.business_id,
        data.document_id
      );

      if (businessSupportDocumentResult.message != null) {
        logEvent(this.$analytics, "delete-business-support-document-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessSupportDocumentResult);
      } else {
        logEvent(this.$analytics, "delete-business-support-document-master-business", { result: "success" });
      }

      state.commit("setLoading", false);

      return businessSupportDocumentResult.data;
    },

    async createOrUpdateBusinessSupportDocument(state, data) {
      const businessSupportDocumentsRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      if (masterBusinessId == null) {
        return;
      }

      const businessSupportDocumentResult = await businessSupportDocumentsRepository.createOrUpdateMasterBusiness(
        masterBusinessId,
        data.business_id,
        data.documents
      );

      if (businessSupportDocumentResult.message != null) {
        logEvent(this.$analytics, "create-business-support-document-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessSupportDocumentResult);
      } else {
        logEvent(this.$analytics, "create-business-support-document-master-business", { result: "success" });
      }

      state.commit("setLoading", false);

      return businessSupportDocumentResult;
    },

    async validateBusinessSupportDocuments(state, businessId) {
      const businessSupportDocumentsRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      if (masterBusinessId == null) {
        return;
      }

      const businessSupportDocumentResult = await businessSupportDocumentsRepository.validateMasterBusiness(masterBusinessId, businessId);

      if (businessSupportDocumentResult.message != null) {
        logEvent(this.$analytics, "validate-business-business-support-document-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessSupportDocumentResult);
      } else {
        logEvent(this.$analytics, "validate-business-business-support-document-master-business", { result: "success" });
        state.commit("setValidateSupportDocuments", businessSupportDocumentResult.data);
      }

      state.commit("setLoading", false);

      return businessSupportDocumentResult.data;
    },

    async typeBusinessSupportDocuments(state, businessId) {
      const businessSupportDocumentsRepository = new BusinessSupportDocumentsRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      if (masterBusinessId == null) {
        return;
      }

      const businessSupportDocumentResult = await businessSupportDocumentsRepository.typeMasterBusiness(masterBusinessId, businessId);

      if (businessSupportDocumentResult.message != null) {
        logEvent(this.$analytics, "type-business-business-support-document-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessSupportDocumentResult);
      } else {
        logEvent(this.$analytics, "type-business-business-support-document-master-business", { result: "success" });
        state.commit("setTypeSupportDocuments", businessSupportDocumentResult.data);
      }

      state.commit("setLoading", false);

      return businessSupportDocumentResult.data;
    },

    async submitMasterBusiness(state, businessId) {
      state.commit("setLoading", true);

      const masterBusinessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessResult = await masterBusinessRepository.submitMasterBusiness(
        masterBusinessId,
        businessId
      );

      state.commit("setLoading", false);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "submit-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
        return null;
      } else {
        logEvent(this.$analytics, "submit-master-business", { result: "success" });
        state.commit("setBusiness", businessResult.data);
      }

      return businessResult;
    },

    async getListMasterBusiness(state, params = {}) {
      state.commit("setLoading", true);

      const masterBusinessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessResult = await masterBusinessRepository.getListBusinessByMasterMerchantId(masterBusinessId, params);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-list-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-list-master-business", { result: "success" });
        state.commit("setListBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult;
    },

    async getMasterBusinessById(state, businessId) {
      state.commit("setLoading", true);

      const masterBusinessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessResult = await masterBusinessRepository.getMasterBusinessById(
        masterBusinessId,
        businessId
      );

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-master-business-by-id", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-master-business-by-id", { result: "success" });
        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    async getMasterBusiness(state, params = {}) {

      // state.commit("setLoading", true);

      const masterBusinessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;

      const businessResult = await masterBusinessRepository.getMasterBusiness(
        masterBusinessId,
        params
      );

      const all_business_for_select = [];
      businessResult.data?.forEach((business) => {
        all_business_for_select.push({
          id: business.id,
          name: business.name
        });
      });

      if (businessResult.message != null) {
        logEvent(this.$analytics, "get-master-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "get-master-business", { result: "success" });
        state.commit("setAllBusiness", businessResult.data);
        state.commit("setAllBusinessForSelect", all_business_for_select);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },

    async copyBusiness(state, businessId) {

      state.commit("setLoading", true);

      const businessRepository = new MasterBusinessRepository();
      const authRepository = new AuthRepository();

      const masterBusinessId = authRepository.getSelectedRole()?.business_id;
      const businessResult = await businessRepository.copyBusiness(masterBusinessId, businessId);

      if (businessResult.message != null) {
        logEvent(this.$analytics, "copy-business", { result: "failed" });
        state.commit("setErrorMessage", businessResult);
      } else {
        logEvent(this.$analytics, "copy-business", { result: "success" });
        state.commit("setBusiness", businessResult.data);
      }

      state.commit("setLoading", false);

      return businessResult.data;
    },
  },
};
