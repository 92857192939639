<template>
  <app-full-modal :title="$t('invoice.action.view_invoice') + ' ' + invoice._id">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="invoice">
        <div class="flex flex-col items-center w-full sm:py-10">
          <div
            class="
              bg-gray-50
              flex flex-col
              bg-white
              rounded-md
              w-11/12
              max-w-full
              border
            "
          >
            <div class="border-t-4 border-b-4 border-purple-700 rounded-md">
              <div class="flex flex-row px-12 pt-8 pb-5">
                <!-- business info -->
                <div class="flex flex-col w-1/2 space-y-2">
                  <app-image
                    v-if="getBusinessLogo"
                    :src="getBusinessLogo"
                    class="flex-none w-24 mt-16 mb-2 bg-white"
                  />
                  <app-image
                    v-else-if="getBusinessIcon"
                    :src="getBusinessIcon"
                    class="flex-none w-24 mt-16 mb-2 bg-white"
                  />
                  <p class="text-gray-900 text-sm uppercase font-medium">
                    {{ business?.name }}
                  </p>
                  <p class="text-gray-400 text-sm uppercase">
                    {{ business?.detail?.company_reg_no }}
                  </p>
                </div>
                <!-- end business info -->
                <!-- invoice summary -->
                <div class="w-1/2 space-y-5">
                  <p
                    class="
                      font-bold
                      text-lg text-center
                      md:text-right
                      uppercase
                    "
                  >
                    {{ $t("layout.header.invoice") }}
                  </p>
                  <div
                    class="
                      flex flex-col
                      space-y-1
                      text-sm text-right text-gray-600
                    "
                  >
                    <div class="flex flex-row space-x-2 justify-end">
                      <p>{{ $t("invoice.invoice_pdf.invoice_no") }}:</p>
                      <p class="w-32">
                        {{
                          invoice.customer.invoice_prefix
                            ? invoice.customer.invoice_prefix
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="flex flex-row space-x-2 justify-end">
                      <p>{{ $t("invoice.invoice_pdf.date") }}:</p>
                      <p class="w-32">
                        {{
                          invoice.created_at
                            ? $moment(invoice.created_at).format("DD MMM YYYY")
                            : "-"
                        }}
                      </p>
                    </div>
                    <div class="flex flex-row space-x-2 justify-end">
                      <p>{{ $t("invoice.invoice_pdf.due_date") }}:</p>
                      <p class="w-32">
                        {{
                          invoice.payment_term_date
                            ? $moment(invoice.payment_term_date).format(
                                "DD MMM YYYY"
                              )
                            : "-"
                        }}
                      </p>
                    </div>
                    <div
                      v-for="att in nonEmptyCustomAttributes"
                      :key="att.key"
                      class="flex flex-row space-x-2 justify-end"
                    >
                      <p class="capitalize">{{ att.key }}:</p>
                      <p class="w-32 capitalize">{{ att.value }}</p>
                    </div>
                  </div>
                  <div class="flex flex-col space-y-2">
                    <div
                      class="
                        flex flex-row
                        justify-end
                        items-center
                        space-x-1.5
                        text-gray-600
                      "
                    >
                      <p class="text-sm">
                        {{ $t("invoice.invoice_pdf.amount_to_pay") }}
                      </p>
                      <app-test-mode-badge :testMode="testMode" />
                    </div>
                    <p class="font-semibold text-3xl text-right">
                      {{
                        $formats.currency(
                          invoice.currency,
                          invoice.total_amount
                        )
                      }}
                    </p>
                    <p
                      v-if="invoice.type"
                      class="text-sm text-gray-400 text-right"
                    >
                      {{ $t(`invoice.create.types.${invoice.type}`) }}
                    </p>
                  </div>
                </div>
                <!-- end invoice summary -->
              </div>

              <div
                class="
                  border-b border-gray-200
                  bg-white
                  py-4
                  inline-block
                  min-w-full
                  sm:px-12
                  lg:px-12
                  justify-center
                "
              >
                <div
                  class="flex flex-col md:flex-row gap-4 pb-2"
                  v-for="product in invoice.items"
                  :key="product._id"
                >
                  <div class="w-1/4">
                    <p class="text-sn text-gray-700 mb-2 space-x-2">
                      <span class="font-bold capitalize">{{
                        product.name ? product.name : "-"
                      }}</span>
                      <span>{{ "(x" + product.quantity + ")" }}</span>
                    </p>
                  </div>
                  <div class="w-1/4">
                    <p class="text-sm font-medium text-gray-700 mb-2">
                      {{
                        `${$formats.currency(
                          product.currency,
                          product.price
                        )}/${product.unit_label}`
                      }}
                    </p>
                  </div>
                  <div class="w-1/2 sm:items-end text-right">
                    <p class="text-sm font-medium text-gray-700 mb-2">
                      {{ $formats.currency(product.currency, product.price) }}
                    </p>
                    <div class="grid grid-cols-2 sm:text-right">
                      <div class="text-sm text-gray-600">
                        <p class="text-sm pb-1">
                          {{
                            $t("invoice.create.tax") +
                            (product.tax_type
                              ? ` (${$t(`tax.types.${product.tax_type}`)}):`
                              : ":")
                          }}
                        </p>
                      </div>
                      <div
                        class="text-sm items-end text-gray-600 sm:text-right"
                      >
                        <p class="text-sm pb-1 text-gray-600">
                          {{ product.tax_name ? product.tax_name : "-" }} /
                          {{ product.tax_rate ? `${product.tax_rate}%` : "-" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="
                  bg-white
                  py-4
                  inline-block
                  min-w-full
                  sm:px-12
                  lg:px-12
                  justify-center
                "
              >
                <div class="flex flex-col md:flex-row gap-4">
                  <div class="w-1/4"></div>
                  <div class="w-1/4"></div>
                  <div class="w-1/2 sm:items-end text-right">
                    <div class="grid grid-cols-2 sm:text-right">
                      <div class="text-sm">
                        <p class="text-sm font-bold pb-2">
                          {{ $t("invoice.invoice_pdf.subtotal") }}:
                        </p>
                        <p class="text-sm pb-1 text-gray-600">
                          {{
                            `${$t("invoice.create.tax")}${
                              invoice.tax_type
                                ? ` (${$t(`tax.types.${invoice.tax_type}`)})`
                                : ""
                            }:`
                          }}
                        </p>
                        <p class="text-sm pb-1 text-gray-600">
                          {{ $t("invoice.create.tax_amount") }}:
                        </p>
                        <p
                          class="
                            text-sm
                            py-2
                            font-semibold
                            text-xl text-primary
                          "
                        >
                          {{ $t("invoice.invoice_pdf.total_due") }}:
                        </p>
                      </div>
                      <div class="text-sm items-end sm:text-right">
                        <p class="text-sm font-bold pb-2">
                          {{
                            $formats.currency(
                              invoice.currency,
                              invoice.subtotal
                            )
                          }}
                        </p>
                        <p class="text-sm pb-1 text-gray-600">
                          {{ invoice.tax?.name ? invoice.tax?.name : "-" }} /
                          {{
                            invoice.tax?.rate ? `${invoice.tax?.rate}%` : "-"
                          }}
                        </p>
                        <p class="text-sm pb-1 text-gray-600">
                          {{
                            $formats.currency(
                              invoice.currency,
                              invoice.tax?.amount
                            ) ?? "0"
                          }}
                        </p>
                        <p
                          class="
                            text-sm
                            py-2
                            font-semibold
                            text-xl text-primary
                          "
                        >
                          {{
                            $formats.currency(
                              invoice.currency,
                              invoice.total_amount
                            )
                          }}
                        </p>
                        <p
                          v-if="invoice.type"
                          class="text-sm pb-1 text-gray-400"
                        >
                          {{ $t(`invoice.create.types.${invoice.type}`) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="
                  py-4
                  inline-block
                  min-w-full
                  sm:px-12
                  lg:px-12
                  justify-center
                "
              >
                <p class="pb-2 text-xs font-semibold uppercase">
                  {{ $t("invoice.invoice_pdf.memo") }}
                </p>
                <p class="pb-2 text-sm text-gray-400">{{ invoice.memo }}</p>
              </div>
              <div
                class="
                  py-4
                  inline-block
                  min-w-full
                  sm:px-12
                  lg:px-12
                  justify-center
                "
              >
                <div class="flex flex-wrap justify-between gap-x-2">
                  <div>
                    <p class="pb-2 text-xs font-semibold uppercase">
                      {{ $t("invoice.invoice_pdf.to") }}
                    </p>
                    <p class="text-sm font-semibold pb-2">
                      {{ invoice.customer.name }}
                    </p>
                    <p class="text-sm pb-2 text-gray-400">
                      {{ invoice.customer.email }}
                    </p>
                    <p class="text-sm pb-2 text-gray-400">
                      {{ invoice.customer.phone_no }}
                    </p>
                  </div>
                  <div>
                    <p class="pb-2 text-xs font-semibold uppercase">
                      {{ $t("invoice.invoice_pdf.billing_address") }}
                    </p>
                    <p class="text-sm pb-2 whitespace-pre-line text-gray-400">
                      {{ customerBillingAddress }}
                    </p>
                  </div>
                  <div>
                    <p class="pb-2 text-xs font-semibold uppercase">
                      {{ $t("invoice.invoice_pdf.shipping_address") }}
                    </p>
                    <p class="text-sm pb-2 whitespace-pre-line text-gray-400">
                      {{ customerShippingAddress }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="
                  border-t border-b border-gray-200
                  pt-4
                  pb-12
                  inline-block
                  min-w-full
                  sm:px-12
                  lg:px-12
                  justify-center
                "
              >
                <p class="text-sm text-gray-400">{{ invoice.footer }}</p>
              </div>
              <div class="justify-center pb-8 mt-10">
                <app-logo-powered-by-payje-dark />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
export default {
  data() {
    return {
      showDetails: false,
      testMode: false,
    };
  },
  props: {
    invoice: {
      type: Object,
      default: null,
    },
    resendEmailButton: {
      type: Boolean,
      default: true,
    },
    showBusinessDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },
    message() {
      return this.$store.getters["paymentStore/message"];
    },
    business() {
      return this.masterBusinessMode
        ? this.invoice.business
        : this.$store.getters["businessStore/business"];
    },
    getBusinessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
    getBusinessIcon() {
      return this.business.media?.find(
        (media) => media.collection_name == "icon"
      )?.original_url;
    },
    customerBillingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("billing");
    },
    customerShippingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("shipping");
    },
    nonEmptyCustomAttributes() {
      return this.invoice.custom_attributes.filter(
        (item) => item.key && item.value
      );
    },
  },
};
</script>
