<template>
  <app-full-modal :title="$t('wallet.payout.payout_detail')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="payout">
        <!--Payout Details -->

        <div class="p-5 space-y-14">
          <div class="space-y-4">
            <div>
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.payout") }}
              </p>
            </div>
            <div class="flex flex-row space-x-2 items-center">
              <p class="font-semibold">
                {{ $formats.currency(payout.currency, payout.amount ?? 0.0) }}
              </p>
              <app-badge
                class="capitalize text-xs w-20"
                :status="getStatus(payout.status)"
              >
                {{ payout.status ?? "-" }}
              </app-badge>
            </div>

            <div class="grid grid-cols-3 text-sm space-x-3">
              <div class="border-r-2">
                <p class="row-span-2 text-gray-400">
                  {{ $t("wallet.payout.date") }}
                </p>

                <p class="col-span-4">
                  {{ $moment(payout.updated_at).format("DD MMM YYYY hh:mm a") }}
                </p>
              </div>

              <div class="border-r-2">
                <p class="col-span-1 text-gray-400">
                  {{ $t("wallet.payout.bank_acc") }}
                </p>
                <div class="flex flex-row justify-between mr-3">
                  <p class="col-span-1 capitalize">
                    {{ payout.business.bank?.bank.display_name ?? "-" }}
                  </p>
                  <app-icon-withdraw />
                </div>
              </div>

              <div class="">
                <p class="col-span-1 text-gray-400">
                  {{ $t("wallet.payout.email_address") }}
                </p>
                <p class="col-span-4">
                  {{ payout.business?.users[0]?.email ?? "-" }}
                </p>
              </div>
            </div>
          </div>

          <!-- Payout Details -->
          <div
            v-if="
              payout.status === PAYOUT_STATUS.PENDING &&
              payout.histories &&
              payout.histories.length > 0
            "
          >
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.remark") }}
              </p>
              <p class="col-span-4">
                {{
                  payout.histories[payout.histories.length - 1].reason || "-"
                }}
              </p>
            </div>
          </div>

          <!--Attempt-->

          <!-- History-->
          <div v-if="payout.histories">
            <div
              v-for="(history, index) in payout.histories.slice(1).reverse()"
              :key="index"
            >
              <div class="space-y-3">
                <p class="text-gray-400 text-sm" v-if="index == 0">
                  {{ $t("payment.detail.settlement_history") }}
                </p>
              </div>
              <div>
                <div class="flex space-x-2 place-items-center">
                  <app-payment-detail-attempt-status :status="history.status" />
                  <p class="text-sm">
                    {{
                      $t("payout.table.settlement_history." + history.status)
                    }}
                  </p>
                </div>
                <div class="flex flex-col mt-2 ml-3.5 border-l-2 border-dotted">
                  <div class="ml-6 pb-4 space-y-1">
                    <p class="text-sm text-red-500">
                      {{ history.reason }}
                    </p>
                    <p class="text-xs text-gray-400">
                      {{
                        $moment(
                          Number(history.date?.$date?.$numberLong)
                        ).format("DD MMM YYYY hh:mm a")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!--Settlement requested-->
            <div>
              <div class="flex space-x-2 place-items-center">
                <span
                  class="flex place-items-center space-x-1 p-1 filter grayscale"
                >
                  <app-icon-small-logo class="mb-1.5" />
                </span>
                <p class="capitalize text-sm">
                  {{ payout.histories[0].status }}
                </p>
              </div>
              <div class="flex flex-col ml-4">
                <div class="ml-6 pb-2">
                  <p class="text-xs text-gray-400">
                    {{
                      $moment(
                        Number(payout.histories[0].date?.$date?.$numberLong)
                      ).format("DD MMM YYYY hh:mm a")
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Proof Document Details -->

          <div class="space-y-3" v-if="!$lodash.isEmpty(payout.media)">
            <div class="pb-2 border-b-2">
              <p class="col-span-1 font-semibold" width="120px">
                {{ $t("wallet.payout.document") }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm" v-if="payout.reference_id">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.ref_id") }}
              </p>
              <p class="col-span-4">
                {{ payout.reference_id ?? "-" }}
              </p>
            </div>
            <div>
              <div
                v-for="(document, index) in payout.media"
                :key="index"
                class="py-2 px-4 flex flex-col sm:flex-row sm:space-x-3 space-x-0 sm:space-y-0 space-y-2 sm:items-center items-start justify-between border-b border-gray-200"
                :class="{ 'border-t': index === 0 }"
              >
                <div class="flex-1 flex items-left items-center space-x-5">
                  <app-icon-jpg
                    v-if="
                      document.mime_type.includes('jpeg') ||
                      document.mime_type.includes('jpg')
                    "
                  />
                  <app-icon-png
                    v-else-if="document.mime_type.includes('png')"
                  />
                  <app-icon-pdf
                    v-else-if="document.mime_type.includes('pdf')"
                  />
                  <p class="text-sm">
                    {{ attachmentFileName(payout.reference_id, document) }}
                  </p>
                </div>

                <div class="flex space-x-5">
                  <p class="text-xs text-gray-500 mt-1">
                    {{ Math.round(document.size / 1024) }} KB
                  </p>
                  <a
                    :href="document.original_url"
                    class="flex space-x-1 items-center cursor-pointer text-black"
                    target="_blank"
                  >
                    <p class="text-sm">{{ $t("general.view") }}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!--Account Bank Details -->

          <div class="space-y-3">
            <div class="pb-2 border-b-2">
              <p class="col-span-1 font-semibold" width="120px">
                {{ $t("wallet.payout.account_bank_details") }}
              </p>
            </div>
            <div v-if="isMasterBusiness" class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.business_name") }}
              </p>
              <p class="col-span-4">
                {{ payout.business?.name }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.bank_name") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.bank?.bank.display_name ?? "-" }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.account_no") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.bank?.account_no ?? "-" }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.origin") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.country?.name ?? "-" }}
              </p>
            </div>
            <div class="grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("wallet.payout.issuer") }}
              </p>
              <p class="col-span-4">
                {{ payout.business.bank?.bank.full_name ?? "-" }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showBusiness">
        <!-- Transaction Details -->
        <p class="mt-5 font-medium px-4 py-2">
          {{ $t("wallet.transaction.business_detail") }}
        </p>
        <hr />

        <div class="p-5 space-y-2">
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.business_id") }}
            </p>
            <p class="col-span-4">{{ payout.business?.id ?? "-" }}</p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.business_name") }}
            </p>
            <p class="col-span-4">{{ payout.business?.name ?? "-" }}</p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.created_at") }}
            </p>
            <p class="col-span-4">
              {{
                $moment(payout.business?.created_at).format(
                  "DD MMM YYYY hh:mm a"
                )
              }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("wallet.transaction.submitted_at") }}
            </p>
            <p class="col-span-4">
              {{
                $moment(payout.business?.submitted_at).format(
                  "DD MMM YYYY hh:mm a"
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="px-5 mt-2"
        v-if="
          payout.type == 'auto' &&
          filterRelevantWallet(payout.wallets, payout._id).length > 0
        "
      >
        <div class="pb-2 border-b-2">
          <p class="col-span-1 font-semibold" width="120px">
            {{ $t("wallet.payout.summary") }}
          </p>
        </div>
        <div class="overflow-x-auto">
          <table>
            <thead class="border-b-2">
              <th class="font-bold pl-0 pr-0">
                {{ $t("wallet.transaction.description") }}
              </th>
              <th class="font-bold">{{ $t("wallet.transaction.count") }}</th>
              <th class="font-bold">
                {{ $t("wallet.overview.header.amount") }}
              </th>
              <th class="font-bold">{{ $t("wallet.transaction.fee") }}</th>
              <th class="font-bold">
                {{ $t("wallet.transaction.net_amount") }}
              </th>
              <th class="font-bold pr-0">{{ $t("general.action") }}</th>
            </thead>
            <tbody class="border-b-2">
              <tr
                v-for="wallet in filterRelevantWallet(
                  payout.wallets,
                  payout._id
                )"
                :key="wallet._id"
              >
                <td class="pl-0">{{ wallet.description }}</td>
                <td class="text-center">
                  {{ wallet.reference?.summary?.count ?? 0 }}
                </td>
                <td>
                  {{
                    $formats.currency(
                      wallet.currency,
                      wallet.reference?.summary?.amount ?? 0
                    )
                  }}
                </td>
                <td>
                  {{
                    $formats.currency(
                      wallet.currency,
                      wallet.reference?.summary?.charge ?? 0
                    )
                  }}
                </td>
                <td>
                  {{
                    $formats.currency(
                      wallet.currency,
                      getNetAmount(wallet) ?? 0
                    )
                  }}
                </td>
                <td class="pr-0">
                  <div class="flex justify-center" v-if="isSettlement(wallet)">
                    <app-link
                      :to="{
                        name: processedPaymentLinkRoute,
                        params: {
                          settlement_id: wallet.reference_id,
                          business_id: wallet.business_id,
                        },
                      }"
                    >
                      <app-button-outline>
                        {{ $t("wallet.transaction.view_payment_details") }}
                      </app-button-outline>
                    </app-link>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="font-bold pl-0 pr-0" colspan="4">
                  {{ $t("wallet.transaction.total") }}
                </td>
                <td class="font-bold pr-0">
                  {{ $formats.currency(payout.currency, payout.amount ?? 0.0) }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
import Role from "@/utils/const/role";
import PAYOUT_STATUS from "@/utils/const/payout_status";

export default {
  props: {
    payout: {
      type: Object,
      default: null,
    },
    showBusiness: {
      type: Boolean,
      default: false,
    },
    processedPaymentLinkRoute: {
      type: String,
      default: "balance.transaction.payment-link",
    },
  },
  data() {
    return {
      PAYOUT_STATUS: PAYOUT_STATUS,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["walletStore/loading"];
    },

    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case "approved":
          return "success";
        case "processing":
          return "light";
        case "requested":
          return "light";
        case "rejected":
          return "error";
        case "pending":
          return "warning";
        case "returned":
          return "light";
      }
    },

    attachmentFileName(referenceId, document) {
      if (document.mime_type.includes("jpeg")) return referenceId + ".jpeg";
      else if (document.mime_type.includes("jpg")) return referenceId + ".jpeg";
      else if (document.mime_type.includes("pdf")) return referenceId + ".pdf";
      else return referenceId;
    },

    filterRelevantWallet(wallets, payout_id) {
      return wallets.filter(
        (wallet) =>
          wallet.reference_model != "Modules\\Wallet\\Entities\\Payout" &&
          wallet.reference_id != payout_id
      );
    },

    getNetAmount(wallet) {
      if (!this.isSettlement(wallet)) return wallet.amount;

      return wallet.reference?.summary?.net;
    },

    isSettlement(wallet) {
      return wallet.reference_model == "Modules\\Wallet\\Entities\\Settlement";
    },

    getPaymentLinkRoute() {
      return this.selectedRole.business_id != null
        ? ""
        : "support.balance.transaction.payment-link";
    },
  },
};
</script>
