import AuthRepository from "../../data/repository/auth-repository";
import PaymentRepository from "../../data/repository/payment-repository";
import baseStore from "../base-store";
import { logEvent } from "firebase/analytics";
import Role from "@/utils/const/role";

const initialOpenPaymentLink = {
  _id: null,
  id: null,
  title: null,
  currency: "MYR",
  description: null,
  reference: null,
  reference_2: null,
  redirect_url: null,
  minimum_amount: 0.0,
  business_id: null,
};

const checkRoleMasterBusiness = [
  Role.MASTER_BUSINESS_OWNER,
  Role.MASTER_BUSINESS_ADMIN,
  Role.MASTER_BUSINESS_MANAGER,
  Role.MASTER_BUSINESS_DEVELOP,
  Role.MASTER_BUSINESS_SUPPORT,
];

export default {
  namespaced: true,
  state: {
    ...baseStore.state,

    open_payment_link: {},
    item_change_loading: false,
  },

  getters: {
    ...baseStore.getters,

    open_payment_link(state) {
      return state.open_payment_link;
    },
  },

  mutations: {
    ...baseStore.mutations,

    initOpenPaymentLink(state, open_payment_link = initialOpenPaymentLink) {
      state.open_payment_link = this.$lodash.clone(initialOpenPaymentLink);
    },
    setOpenPaymentLink(state, open_payment_link) {
      state.open_payment_link = open_payment_link;
    },
    setItemChangeLoading(state, item_change_loading) {
      state.item_change_loading = item_change_loading;
    },
  },

  actions: {
    async retrieveOpenPaymentLinks(state, filters) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      let openPaymentLinkIndexResult =
        await paymentRepository.openPaymentLinkIndex(business_id, filters);

      if (
        checkRoleMasterBusiness.includes(
          authRepository.getSelectedRole()?.role_id
        )
      ) {
        openPaymentLinkIndexResult =
          await paymentRepository.openPaymentLinkIndexMasterBusiness(
            business_id,
            filters
          );
      }

      if (openPaymentLinkIndexResult.message != undefined) {
        logEvent(this.$analytics, "open-payment-link-index", {
          result: "failed",
        });
        state.commit("setErrorMessage", openPaymentLinkIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "open-payment-link-index", {
          result: "success",
        });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationResponse", openPaymentLinkIndexResult);
      return openPaymentLinkIndexResult;
    },

    async retrieveOpenPaymentLinksPaymentLinks(state, params) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setLoading", true);

      let openPaymentLinkIndexResult = null;
      let hasMasterBusiness = checkRoleMasterBusiness.includes(
        authRepository.getSelectedRole()?.role_id
      );
      if (hasMasterBusiness) {
        openPaymentLinkIndexResult =
          await paymentRepository.openPaymentLinkIndexMasterBusiness(
            business_id,
            params.open_payment_link_id,
            params.filters
          );
      } else {
        openPaymentLinkIndexResult =
          await paymentRepository.openPaymentLinkPaymentLinkIndex(
            business_id,
            params.open_payment_link_id,
            params.filters
          );
      }

      if (openPaymentLinkIndexResult.message != undefined) {
        logEvent(this.$analytics, "open-payment-link-payment-link", {
          result: "failed",
        });
        state.commit("setErrorMessage", openPaymentLinkIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "open-payment-link-payment-link", {
          result: "success",
        });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationResponse", openPaymentLinkIndexResult);
      return openPaymentLinkIndexResult;
    },

    async createOrUpdateOpenPaymentLink(state) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      let business_id = authRepository.getSelectedRole()?.business_id;

      if (
        checkRoleMasterBusiness.includes(
          authRepository.getSelectedRole()?.role_id
        )
      ) {
        business_id = state.getters.open_payment_link?.business?.id;
      }

      if (business_id == null) {
        state.commit("setError", {
          business_id: [
            this.$t("general.please_select", {
              name: this.$t("general.business"),
            }),
          ],
        });
        return;
      }

      state.commit("setLoading", true);

      const isCreate = state.getters.open_payment_link._id == null;

      Object.keys(state.getters.open_payment_link).forEach((key) => {
        if (key == "status" && !isCreate) {
          state.getters.open_payment_link["status_id"] = state.getters
            .open_payment_link[key]
            ? 1
            : 2;
        }

        if (state.getters.open_payment_link[key] === "") {
          delete state.getters.open_payment_link[key];
        }
      });

      const openPaymentLinkResult =
        await paymentRepository.openPaymentLinkCreateOrUpdate(
          business_id,
          state.getters.open_payment_link
        );

      if (openPaymentLinkResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "create-open-payment-link" : "update-open-payment-link",
          { result: "failed" }
        );
        state.commit("setErrorMessage", openPaymentLinkResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "create-open-payment-link" : "update-open-payment-link",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return openPaymentLinkResult;
    },

    async updateStatusOpenPaymentLink(state, status) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const business_id = authRepository.getSelectedRole()?.business_id;

      if (business_id == null) {
        return;
      }

      state.commit("setItemChangeLoading", true);

      let openPaymentLinkUpdateStatusResult = null;
      let hasMasterBusiness = checkRoleMasterBusiness.includes(
        authRepository.getSelectedRole()?.role_id
      );
      if (hasMasterBusiness) {
        openPaymentLinkUpdateStatusResult =
        await paymentRepository.openPaymentLinkUpdateStatusMasterBusiness(
          state.getters.open_payment_link.business_id,
          state.getters.open_payment_link._id,
          status ? 1 : 2
        );
      } else {
        openPaymentLinkUpdateStatusResult =
        await paymentRepository.openPaymentLinkUpdateStatus(
          business_id,
          state.getters.open_payment_link._id,
          status ? 1 : 2
        );
      }

      if (openPaymentLinkUpdateStatusResult.message != undefined) {
        logEvent(this.$analytics, "update-status-open-payment-link", {
          result: "failed",
        });
        state.commit("setItemChangeLoading", false);
        state.commit("setErrorMessage", openPaymentLinkUpdateStatusResult);
        return;
      } else {
        logEvent(this.$analytics, "update-status-open-payment-link", {
          result: "success",
        });
      }

      state.commit("setItemChangeLoading", false);
      return openPaymentLinkUpdateStatusResult;
    },

    async findOpenPaymentLink(state, openPaymentLinkId) {
      const paymentRepository = new PaymentRepository();

      state.commit("setLoading", true);

      const openPaymentLinkResult = await paymentRepository.getOpenPaymentLink(
        openPaymentLinkId
      );

      if (openPaymentLinkResult.message != undefined) {
        logEvent(this.$analytics, "get-open-payment-link", {
          result: "failed",
        });
        state.commit("setErrorMessage", openPaymentLinkResult);
        return;
      } else {
        logEvent(this.$analytics, "get-open-payment-link", {
          result: "success",
        });
      }

      state.commit("setOpenPaymentLink", openPaymentLinkResult.data);
      state.commit("setLoading", false);
      return openPaymentLinkResult;
    },

    async proceedOpenPaymentAttempt(state, data) {
      const paymentRepository = new PaymentRepository();

      state.commit("setLoading", true);

      const paymentPaymentAttemptResult =
        await paymentRepository.proceedOpenPaymentAttempt(
          data.open_payment_link_id,
          data.business_payment_gateway_method_id,
          data.email,
          data.name,
          data.phone_no,
          data.amount,
          data.card_info,
          data.bank_info,
          data.stripe_create_payment_result
        );

      if (paymentPaymentAttemptResult.message != undefined) {
        logEvent(this.$analytics, "proceed-open-payment-attempt", {
          result: "failed",
        });
        state.commit("setErrorMessage", paymentPaymentAttemptResult);
      } else {
        logEvent(this.$analytics, "proceed-open-payment-attempt", {
          result: "success",
        });
      }

      return paymentPaymentAttemptResult.data;
    },

    async getOpenPaymentLinkPublic(state, openPaymentLinkId) {
      const paymentRepository = new PaymentRepository();

      state.commit("setLoading", true);

      const openPaymentLinkResult =
        await paymentRepository.getOpenPaymentLinkPublic(openPaymentLinkId);

      if (openPaymentLinkResult.message != undefined) {
        logEvent(this.$analytics, "get-open-payment-link-public", {
          result: "failed",
        });
        state.commit("setErrorMessage", openPaymentLinkResult);
      } else {
        logEvent(this.$analytics, "get-open-payment-link-public", {
          result: "success",
        });
        state.commit("setOpenPaymentLink", openPaymentLinkResult.data ?? {});
      }

      state.commit("setLoading", false);

      return openPaymentLinkResult;
    },
  },
};
