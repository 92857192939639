export default {
  "app": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payje"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payje"])},
    "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payje"])}
  },
  "general": {
    "swipego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SwipeGo"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    "master_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Business"])},
    "type_of_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of Business"])},
    "company_annual_turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Annual Turnover"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "placeholder_bank_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for your bank"])},
    "industries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industries"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "retype_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-type your password"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "term_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "placeholder_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved & Continue"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
    "unable_to_reach_server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we're unable to reach the server right now."])},
    "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to retry"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
    "field_required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("name")), " field is required."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "no_more_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more results"])},
    "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
    "please_select": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please Select ", _interpolate(_named("name"))])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
    "week_to_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week-to-date"])},
    "month_to_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month-to-date"])},
    "year_to_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year-to-date"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
    "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "test_mode_environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Mode Environment"])},
    "test_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Mode"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent!"])},
    "email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved!"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "return_homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Homepage"])},
    "data_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data has been saved."])},
    "no_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found"])},
    "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
    "address": {
      "line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 1"])},
      "line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 2"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])}
    },
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "edit_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit information"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "view_more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view more details"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "generated_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERATED_ID"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "submitting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting..."])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
    "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "unsaved_changes_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to leave? you have unsaved changes!"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "download_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid link"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read less"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Optional)"])}
  },
  "message": {
    "resend_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Successfully"])},
    "update_successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Successful"])},
    "send_successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Successfully"])}
  },
  "notification": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
    "unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unread"])},
    "mark_all_as_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as read"])}
  },
  "modal": {
    "app_cropper_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust and crop your preffered area of the photo"])}
  },
  "datatable": {
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show: "])},
    "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Page"])},
    "empty_item_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no item found"])},
    "empty_item_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items found."])},
    "empty_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new Item"])},
    "loading_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading data..."])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "active_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active filters"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to "])},
    "clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filter"])}
  },
  "auth": {
    "register": {
      "strength_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register and get fast approval to start your business"])},
      "strength_1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete registration and send all necessary documentation for fast approval"])},
      "strength_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth your revenue 100X with Payje"])},
      "strength_2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus on your business, let's Payje manage your payment(accept, manage, distribute) securely. "])},
      "strength_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payment securely"])},
      "strength_3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payment securely with most powerful payment safety protection"])},
      "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the Payje's"])},
      "user_agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreement"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
      "password_strength_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain the following:"])},
      "password_strength_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Uppercase letter"])},
      "password_strength_special_char": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["1 Special character ", _interpolate(_named("character"))])},
      "password_strength_min_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be longer than 8 characters"])},
      "error_password_strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contains 1 Uppercase letter, 1 Special character and Must be longer than 8 characters"])},
      "resend_email_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have email you to reset your password. Please check your email for instructions."])},
      "rensend_email_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn’t get the email? Try check your spam folder or resend"])},
      "password_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Changed!"])},
      "password_changed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your passsword has been changed successfully"])}
    },
    "login": {
      "title_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your Payje account"])},
      "stay_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay signed in for a week"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account yet?"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
      "reset_my_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset my password"])},
      "reset_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your registered email address and we'll send you a link to reset your password."])},
      "reset_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password did not match"])},
      "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Email"])},
      "return_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to sign in"])}
    },
    "verify": {
      "return_to_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Home"])},
      "verified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email successfully verified."])}
    },
    "role": {
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role not found"])}
    }
  },
  "layout": {
    "navbar": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial"])},
      "integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration"])},
      "test_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Mode"])},
      "list_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Business"])},
      "list_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List User"])},
      "list_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement List"])},
      "audit_trail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Trails"])},
      "telescope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Monitor"])},
      "cron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cron Jobs"])},
      "settlement_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement"])},
      "payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Payment"])},
      "open_payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Customer Payment"])},
      "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])},
      "transaction_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction List"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])}
    },
    "header": {
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
      "payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "payment_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYMENT ONLINE"])},
      "activate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete Details"])},
      "account_under_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Under Review"])},
      "account_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Approved"])},
      "account_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Rejected"])},
      "account_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Suspended"])},
      "account_amended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amend Business Details"])},
      "complete_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete business information"])},
      "business_under_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business under review"])},
      "business_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business approved"])},
      "business_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business rejected"])},
      "business_amended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please amend business information"])}
    }
  },
  "dashboard": {
    "report_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Overview"])},
    "today_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's Summary"])},
    "week_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week's Summary"])},
    "month_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month's Summary"])},
    "year_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year's Summary"])},
    "gross_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Sales"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Balance"])},
    "payouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "total_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Sales"])},
    "net_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Sales"])},
    "total_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Of Customer"])},
    "successful_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Payment"])},
    "avg_spend_per_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Spent Per Customer"])},
    "avg_sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Sales"])},
    "verify_your_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your email address"])},
    "check_email_for_verify": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before you get started, check <b>", _interpolate(_named("email")), "</b> for a link to verify your email."])},
    "didnt_get_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn’t get it? Check your spam folder or"])},
    "add_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Business"])},
    "add_new_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Business"])},
    "feature_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is disabled by your administrator"])},
    "account": {
      "total_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Gross Merchandise Value"])},
      "total_payout_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Settlement Requested"])},
      "total_payout_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Settlement Approved"])},
      "online_b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking B2C"])},
      "online_b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking B2B"])},
      "credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
      "gross_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Volume"])},
      "gross_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Commission"])},
      "net_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Volume"])},
      "top_sales_collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 10 Gross Merchandise Value"])}
    },
    "operation": {
      "business_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registered"])},
      "business_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Created"])},
      "business_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Pending"])},
      "business_amended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Amended"])},
      "business_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rejected"])},
      "business_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Approved"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Gateway"])}
    },
    "master_business": {
      "business_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registered"])},
      "business_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Created"])},
      "business_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Pending"])},
      "business_amended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Amended"])},
      "business_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Approved"])},
      "business_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Rejected"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master-Merchant's Business"])},
      "summary_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Merchant's Summary"])},
      "available_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Balance"])},
      "ref_on_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on daily balance"])},
      "gross_sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Sales"])},
      "net_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Volume"])},
      "payout_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Received"])}
    }
  },
  "business": {
    "main": {
      "structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business structure"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business details"])},
      "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business representative"])},
      "owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business owners"])},
      "support_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support details"])},
      "support_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support documents"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
      "comments_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments/Reasons:"])},
      "business_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business List"])},
      "create_new_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new business"])},
      "upgrade_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business upgraded to master merchant!"])},
      "upgrade_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrading business failed!"])},
      "upgrade_failed_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrading business failed. Reason:"])},
      "business_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact support to make any changes."])}
    },
    "structure": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About your business"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We collect information about your company to guide us meet the standards of regulators, financial partners, and our services agreement."])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "address_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use home address if you don't have any business address."])},
      "country_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered business country"])},
      "info_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Name"])},
      "info_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This name will used as a display name"])},
      "reg_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration No"])},
      "address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business address"])},
      "type_of_business_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of business"])},
      "copy_from_existing_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy from existing business"])}
    },
    "detail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some details on your business"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us to understand your business in better ways."])},
      "company_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
      "company_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
      "company_reg_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company registration no"])},
      "tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax identification number"])},
      "company_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure it’s exactly match as your associated SSM certificate"])},
      "company_reg_no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company number (MyCoID)"])},
      "company_reg_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456-A"])},
      "company_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company location"])},
      "activity_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business activity (optional)"])},
      "activity_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business activity"])},
      "address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company address"])},
      "phone_no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company phone number"])},
      "industry_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "industry_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your industry.."])},
      "annual_turnover_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select annual turnover.."])},
      "website_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company website / Social media"])},
      "website_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can put your business social media profile or your app store link if you dont have a website"])},
      "website_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://yourwebsite.com"])},
      "product_desc_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product description"])},
      "product_desc_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly explain your product or services. This will help us to understand and process your application quickly."])},
      "product_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly explain your product or services. This will help us to understand and process your application quickly."])}
    },
    "representative": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Details"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account must be activated by an admin, finance, or other person with significant responsibility for the control and management of your business. If that isn't you, please have the appropriate person fill out this form."])},
      "legal_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal name of person"])},
      "designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])},
      "job_position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job position"])},
      "first_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "last_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
      "date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd / mm / yyyy"])},
      "home_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Address"])},
      "address_placeholder_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 1"])},
      "address_placeholder_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 2"])},
      "postcode_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "mykad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC (MyKad)"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport No."])}
    },
    "owner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Shareholders"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are required by regulatory guidelines to collect information about the directors in your company."])},
      "add_business_shareholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add business shareholder"])},
      "add_another_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another owner"])},
      "continue_with_no_shareholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with no shareholders"])},
      "label_name_of_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal name of person"])},
      "label_identification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])},
      "label_phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "label_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    },
    "support_detail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Details for Customer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information assists customers in recognising your company."])},
      "business_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your business name"])},
      "statement_descriptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement descriptor"])},
      "statement_descriptor_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A statement descriptor is the business name a customer will see on their card statements. Choose a name that’s at least five characters and easy to recognise to avoid potential chargebacks."])},
      "business_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
      "shortened_descriptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortened descriptor"])},
      "shortened_descriptor_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortened descriptors can be paired with specific details about a charge."])},
      "phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "support_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support email"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    },
    "bank_details": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account for settlements"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A settlement is the transfer of funds from Payje to your bank account."])},
      "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
      "account_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder name"])}
    },
    "support_documents": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting Documents"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are required to upload the below documents to activate your payment method. If you want to add on Credit Card in the future you can request by clicking this link <a href='", _interpolate(_named("url")), "' target='_blank'>Payment Setting</a>"])},
      "description_no_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already uploaded all required documents for verifications."])},
      "description_documents_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your documents are approved. Please contact support to make any changes."])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["are you sure to delete this file?"])},
      "max_file": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum files is ", _interpolate(_named("max"))])},
      "click_or_drag_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click or drag file to this area to upload"])},
      "upload_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload .PDF, .JPG, .PNG Format not more than 10MB. You can upload multiple files at one time"])},
      "preferred_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Payment Method :"])},
      "unsupported_file_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported file type"])},
      "terms_and_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By submitting this form, you agree to <a href='", _interpolate(_named("url")), "' target='_blank'>Terms and Conditions</a> provided by the payment provider."])},
      "payment_method_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method Application"])},
      "payment_method_application_fpx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method Application: FPX"])},
      "payment_method_application_cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method Application: Credit Card (Optional)"])},
      "label": {
        "Director_signatory_ic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director NRIC"])},
        "Director_signatory_ic_cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director’s Signatory NRIC"])},
        "bank_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Bank Statement"])},
        "ssm_llp_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Limited Liability Partnership (LLP) Certificate"])},
        "form_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form A"])},
        "form_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form B"])},
        "document_ssm_form_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form D"])},
        "document_ssm_form_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form 24 / Form 51 & 78"])},
        "document_ssm_form_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form 49 / Form 58"])},
        "document_ssm_form_44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form 44 / Form 46"])},
        "form_m&a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSM Form M&A"])},
        "document_business_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Business Registration"])},
        "document_local_concil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Council Trading License"])},
        "signboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Photo(s) of Business Signboard "])},
        "premises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo(s) of Premises"])},
        "display_of_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo(s) of Products"])},
        "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo(s) of Equipment"])},
        "director_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director Signature"])},
        "company_stamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Stamp"])},
        "complete_ssm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete SSM / E-SSM"])},
        "document_representative_ic_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative Identification Card"])}
      },
      "label_description": {
        "Director_signatory_ic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Director (Front)"])},
        "Director_signatory_ic_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Director (Back)"])},
        "document_representative_ic_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Representative (Front)"])},
        "document_representative_ic_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide NRIC of one Company Representative (Back)"])},
        "bank_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide the latest 3 month bank statement"])},
        "form_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form A"])},
        "form_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form B"])},
        "form_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form D"])},
        "form_m&a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form M&A"])},
        "document_local_concil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide relevant licence (eg: DBKL, MBPJ, MPAJ, MBJB, MPSJ, MPK etc)"])},
        "signboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Please provide at least one photo clearly showing your Business Registration Number"])},
        "premises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide at least one photo clearly showing an overview of your office"])},
        "display_of_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide at least one photo clearly showing the equipment uses for the products"])},
        "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide at least one photo clearly showing the equipment uses for the products"])},
        "director_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide digital signature of the above Company Director"])},
        "company_stamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide digital company stamp"])},
        "document_ssm_form_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form 24 / Form 51 & 78"])},
        "document_ssm_form_49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form 49 / Form 58"])},
        "document_ssm_form_44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide SSM Form 44 / Form 46"])},
        "ssm_llp_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide(LLP Certificate) / Full LLP Certificate"])},
        "complete_ssm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please provide complete SSM / E-SSM. Click here to view example <a href='", _interpolate(_named("url")), "' target='_blank'>Support Document</a>"])}
      }
    },
    "summary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's Review your details"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One step left and you’re almost ready to get started with Payje. Please double-check all the information."])},
      "agreement_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By submitting this form, you agree to"])},
      "agreement_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" provided by Payje, to receive the text message from Payje and you certify that the information provided is complete and correct."])},
      "payje_agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Terms and Conditions"])},
      "detail_company_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company number"])},
      "detail_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business activity"])},
      "detail_company_annual_turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company annual turnover"])},
      "missing_required_business_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing required business information"])},
      "business_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business has been Submitted. Confirmation email will be sent to the merchant shortly."])}
    },
    "table": {
      "header_businss_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])},
      "header_businss_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business type"])},
      "header_merchant_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant type"])},
      "header_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "header_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "header_submitted_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted date"])},
      "header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "header_remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
      "tab_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "tab_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Review"])},
      "tab_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
      "tab_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
      "tab_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "tab_amended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amended"])},
      "tab_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended"])}
    },
    "modal": {
      "approve_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve this business?"])},
      "approve_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign payment provider for this business after approval."])},
      "approve_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve business"])},
      "amend_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amend this business?"])},
      "amend_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state reasons why this business need amendments"])},
      "amend_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "decline_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline this business?"])},
      "decline_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state reasons why this business is declined"])},
      "decline_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline business"])},
      "upgrade_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade this business?"])},
      "upgrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade this business to become master merchant."])},
      "upgrade_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade business"])},
      "create_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create business account first"])},
      "create_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can try our features in the test mode but first you need to create at least 1 business account."])},
      "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Business"])},
      "go_test_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good to go for test mode!"])},
      "go_test_mode_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your test mode are enabled. You can continue register your business informations or go to dashboard to try in the test mode."])},
      "continue_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Registration"])},
      "go_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Dashboard"])},
      "delete_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
      "delete_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won't be able to revert this!"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it!"])},
      "suspend_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend this business?"])},
      "suspend_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state reasons why this business need to be suspended."])},
      "suspend_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend business"])}
    },
    "info": {
      "details_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details Menu"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "edit_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Information"])},
      "cancel_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel editing"])},
      "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve business"])},
      "amend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amend business"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline business"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade business"])},
      "suspend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend business"])}
    },
    "company_logo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize how your brand appears globally across the Payje products your customers use."])},
      "label_icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A smaller version of your logo like favicon or apps icon logo. This will appear on your business account list"])},
      "label_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full size version of your logo. This will appear in your invoice and Customer Payment"])},
      "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload logo"])},
      "label_merchant_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<merchant name>"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])}
    },
    "payment_provider": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider"])}
    },
    "copy": {
      "suggestion_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s make it easy!"])},
      "suggestion_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to copy business details from your other businesses? You can choose which section to copy"])},
      "suggestion_button_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Please"])},
      "suggestion_button_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Thank you"])},
      "select_business_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy From Existing Business"])},
      "select_business_button_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Please"])},
      "select_business_button_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Thank you"])},
      "info_business_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy From:"])},
      "loading_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please wait while we pasting details from ", _interpolate(_named("business_name"))])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Settings"])},
      "payout_option_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement Options"])},
      "payout_option_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose between automatically or on demand settlement to your bank account"])},
      "reset_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to default"])},
      "payout_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically daily"])},
      "payout_on_demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On demand settlement - We will process your settlement once we received your request."])}
    }
  },
  "payment": {
    "menu": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Menu"])},
      "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Payment"])},
      "create_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Payment Link"])},
      "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
      "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
      "payment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment channel"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "approved_payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved payment gateway"])},
      "under_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under review/process"])},
      "fixed_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed charges"])},
      "percentage_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage charges"])},
      "charges_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges type"])},
      "fixed_min_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum charges"])},
      "percentage_min_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum charges"])},
      "min_charges_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum charges type"])},
      "add_payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Payment Gateway"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
      "mixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed"])},
      "enable_payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Payment Gateway"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template:"])},
      "settlement_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Day"])},
      "charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges"])},
      "configs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configs"])},
      "open_payment_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Customer Payment"])},
      "create_open_payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Payment Link"])},
      "stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])}
    },
    "create": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Payment Link"])},
      "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address of customer"])},
      "phone_no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number (Optional)"])},
      "phone_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payer phone no (optional)"])},
      "title_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment title"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of the payment "])},
      "amount_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
      "amount_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.00"])},
      "desc_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of payment (Optional)"])},
      "desc_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the payment in detail"])},
      "ref1_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 1 (Optional)"])},
      "ref1_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for customer reference"])},
      "ref2_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 2 (Optional)"])},
      "ref2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for customer reference"])},
      "redirect_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URL (Optional)"])},
      "redirect_url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect payment URL"])},
      "send_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send this payment link to customer's email"])},
      "preview_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "preview_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide preview"])},
      "preview_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show preview"])},
      "preview_tab_payment_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment page"])},
      "preview_tab_email_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "select_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Business"])},
      "max_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum amount ", _interpolate(_named("max_amount"))])},
      "min_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum amount ", _interpolate(_named("min_amount"))])}
    },
    "link": {
      "phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSACTION PAID"])},
      "amount_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
      "new_payment_request": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have received a new payment request from ", _interpolate(_named("business_name")), "!"])},
      "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
      "online_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online banking"])},
      "select_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bank"])},
      "card_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card information"])},
      "card_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Holder Name"])},
      "card_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0000 0000 0000 0000"])},
      "card_expiry_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM/YY"])},
      "card_ccv_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
      "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed payment"])},
      "ref1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 1"])},
      "ref2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 2"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link has been copied"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is being sent"])},
      "requery": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Payment attempt: ", _interpolate(_named("attempt_id")), " has been lined up for requery, This process might take to few minutes."])},
      "empty": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment link created"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get paid by someone with payment link. Ready to generate revenue?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Customer Payment"])}
      },
      "filter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Amount"])},
        "payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])},
        "payment_link_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment ID"])},
        "payment_attempt_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Attempt Id"])},
        "payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
        "settlement_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Id"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "date_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])}
      },
      "header": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sale Amount"])},
        "charge_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Fees"])},
        "net_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Sales"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
        "system_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Status"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])},
        "payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])},
        "sale_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Amount"])}
      },
      "attempt_status": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempted"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached Thank You Page"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned"])},
        "requires_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires Action"])}
      },
      "payment_methods": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Banking (Business)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit / Debit Card"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit / Debit Card (Tokenization)"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DuitNow Online Banking"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])}
      },
      "fpx_terms_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on the \"Proceed Payment\" button below, you are agreeing to the "])},
      "fpx_terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FPX's Terms and Conditions"])}
    },
    "open-payment-link": {
      "header": {
        "minimum_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "status": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])}
      },
      "filter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "minimum_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "open_payment_link_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment Link Id"])},
        "payment_link_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link Id"])},
        "payment_attempt_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Attempt Id"])},
        "payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
        "settlement_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement Id"])},
        "date_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])}
      },
      "form": {
        "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Payment Link"])},
        "edit_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Open Payment Link"])},
        "minimum_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment title"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner (Optional)"])},
        "ref1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 1 (Optional)"])},
        "ref2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference note 2 (Optional)"])},
        "ref1_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for payment"])},
        "ref2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for payment"])},
        "redirect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URL (Optional)"])},
        "redirect_url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment redirect URL"])},
        "button_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
        "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Details"])},
        "button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Payment Link"])}
      },
      "payment_page": {
        "min_amount_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Amount:"])},
        "amount_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay:"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose:"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])},
        "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (Optional)"])},
        "phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number (Optional)"])},
        "payment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment For:"])},
        "link_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment link is invalid."])},
        "link_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment link is inactive."])},
        "link_inactive_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like the payment link you are looking for is  deactivated by the owner."])},
        "link_inactive_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there any issues with the link, Please email us at"])}
      },
      "qrcode-page": {
        "qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
        "scan_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCAN TO PAY"])},
        "download_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Image"])}
      },
      "modal": {
        "activate_deactivate": {
          "positive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Open Payment Link?"])},
          "positive_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This payment will be visible to anyone who has the links."])},
          "positive_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
          "negative_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate open payment link?"])},
          "negative_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyone with the link will not able to access."])},
          "negative_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
          "skip_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't ask again for this session"])}
        },
        "details": {
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open payment link details"])},
          "view_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Payment Details"])},
          "payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
          "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])},
          "minimum_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Amount:"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])},
          "link_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Opened"])},
          "successful_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Payment"])},
          "unsuccessful_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsuccessful Payment"])},
          "pending_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Payment"])}
        }
      },
      "detail-page": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment: "])}
      },
      "empty": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open payment link created"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get paid by someone with open payment link. Ready to generate revenue?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Open Customer Payment"])}
      }
    },
    "email": {
      "payment_link_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link ID: "])},
      "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for using Payje. This is example for payment link email."])},
      "regards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Regards,"])},
      "open_payment_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Payment Page"])},
      "having_trouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you’re having trouble with the button above, copy and paste the URL below into your web browser."])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about this invoice, contact us at "])},
      "question_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" or call us at "])}
    },
    "info": {
      "active_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your account to create a payment link"])},
      "active_your_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate your account first"])},
      "active_your_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a payment link."])},
      "active_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
      "active_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
      "activate_account_incomplete_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete your business details and submit."])},
      "activate_account_amend_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please amend your business details and resubmit."])},
      "activate_account_under_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yor business is under review. Please wait for approval."])},
      "activate_account_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yor business is rejected. Please contact our Support."])},
      "incomplete_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete Details"])},
      "amend_business_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amend Business Details"])},
      "account_under_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Under Review"])},
      "account_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Rejected"])},
      "account_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Suspended"])},
      "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    },
    "settings": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "payment_provider": {
        "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment gateway to enable"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can enable one payment method at a time"])},
        "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway "])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
        "channel_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Used: "])},
        "fpx_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FPX Payment"])},
        "debit_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit/Credit"])},
        "list_payment_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of payment provider"])},
        "contact_support_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact our support if you don't have any payment provider listed in here"])},
        "no_cc_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently not applying any Credit/Debit Card Payment method."])},
        "apply_cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to apply"])},
        "apply_cc_dialog_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applying for Credit/Debit Card Payment"])},
        "apply_cc_dialog_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to apply for Credit / Debit Card, kindly click 'Apply Now' button. Our team will contact you separately to assist you on this application.  "])},
        "apply_cc_proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Now"])},
        "apply_cc_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application has been sent to our team."])}
      }
    },
    "thank_you": {
      "payment_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment ID: "])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount: "])},
      "paid_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment paid to: "])},
      "paid_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid on: "])},
      "payment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment for: "])},
      "channel_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel used: "])},
      "thank_you_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your payment"])},
      "attempt_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref ID: "])},
      "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider: "])},
      "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method: "])},
      "close_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may close this window"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used bank"])}
    },
    "detail": {
      "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
      "payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Link"])},
      "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Email"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "attempt_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempt history"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
      "payment_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment history"])},
      "payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
      "payment_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Transaction details"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "charge_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges"])},
      "net_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "ref_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref 1"])},
      "ref_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref 2"])},
      "phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No."])},
      "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
      "ref_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
      "requery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requery"])},
      "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process payment"])},
      "processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed"])},
      "settlement_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement history"])},
      "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested"])}
    },
    "config": {
      "stripe": {
        "send_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Documents to Stripe?"])},
        "send_document_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First, you need to send merchant’s document to stripe before setup configs."])},
        "connect_to_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to Stripe"])},
        "resubmit_to_stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resubmit to Stripe"])},
        "modal_title_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to send the documents to Stripe?"])},
        "modal_description_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send merchant’s document to stripe for approval to use their payment gateway."])},
        "modal_action_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Documents"])},
        "no_stripe_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Stripe status found"])}
      },
      "form_merchant_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "form_merchant_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the merchant ID here"])},
      "form_secret_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Key"])},
      "form_secret_key_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the secret key here"])},
      "form_api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key"])},
      "form_api_key_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the API key here"])},
      "no_payment_gateway_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please update the payment charges before create the config."])},
      "update_payment_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update payment charges"])}
    },
    "test_mode": {
      "generete_payment_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Payment Provider"])},
      "generete_payment_provider_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Payment Provider Generated"])}
    }
  },
  "wallet": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement Menu"])},
    "balances_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement Menu"])},
    "account_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floating Amount"])},
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "floating_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floating Amount"])},
      "expected_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Settlement"])},
      "otw_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Settlement"])},
      "on_the_way_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
      "header": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Amount"])},
        "charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges"])},
        "net_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
      },
      "succeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])}
    },
    "reporting": {
      "gross_commision_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Commission Report"])}
    },
    "transaction": {
      "type_transfer_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
      "type_transfer_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Out"])},
      "settled_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled In"])},
      "settled_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settle Out"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "settlement_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Transaction Reference"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
      "processing_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing ID"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
      "gross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges"])},
      "net_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Sales"])},
      "business_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business details"])},
      "transaction_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Details"])},
      "business_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business ID"])},
      "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
      "submitted_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted Date"])},
      "payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
      "view_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Payment Details"])},
      "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "processed-payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed Payment Link"])},
      "sale_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Amount"])}
    },
    "payout": {
      "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
      "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement"])},
      "payout_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement Details"])},
      "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
      "account_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "bank_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account"])},
      "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "account_bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Bank Details"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
      "issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer"])},
      "ref_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw Amount"])},
      "withdraw_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can withdraw up to:"])},
      "withdraw_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw all"])},
      "withdraw_amount_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter withdraw amount"])},
      "request_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Withdraw"])},
      "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof Document"])},
      "original_round_off": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Original amount : <b>", _interpolate(_named("amount")), "</b> <br> Type : <b>", _interpolate(_named("type")), "</b>"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
      "acc_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Holder"])}
    }
  },
  "integration": {
    "menu": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations Menu"])},
      "api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
      "webhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
      "please_activate_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This features for approved business only"])}
    },
    "api": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key"])},
      "api_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration Documentation"])},
      "reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Api Key"])},
      "notes_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes: API keys are for advanced users. Please make sure that you have read the "])},
      "notes_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" before proceeding."])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key has been copied."])},
      "reset_prompt_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset API Key?"])},
      "reset_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure want to reset API Key? This will block existing integration until you update with a new key."])}
    },
    "webhook": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Webhook"])},
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Key"])},
      "reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Signature Key"])},
      "notes_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes: Webhooks are for advanced users. Please make sure that you have read the "])},
      "notes_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" before proceeding."])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Key has been copied."])},
      "reset_prompt_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Signature Key?"])},
      "reset_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure want to reset Signature Key? This will make the system to generate a signature using the old key, Please update the signature key on client side."])},
      "form": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Url"])},
        "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])}
      }
    },
    "webhook_event": {
      "webhook_event_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook logs"])},
      "no_webhook_logs_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No webhook logs found"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
      "exception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "retries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retries"])},
      "no_of_attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No of Attempt"])},
      "last_attempt_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Attempt at"])}
    }
  },
  "user": {
    "detail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Details"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
      "master_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
      "verify_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify at"])},
      "register_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at"])},
      "resend_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Password"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["are you sure to delete this role"])}
    }
  },
  "admin": {
    "audit": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
      "auditable_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Type"])},
      "user_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User email"])},
      "via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger By Type"])},
      "type_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger By Details"])},
      "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
      "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "old_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old values"])},
      "new_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New values"])},
      "auditable_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Id"])},
      "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User id"])},
      "user_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP address"])},
      "user_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User agent"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "made_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made by"])}
    },
    "cron": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement (Auto Payout)"])},
      "settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Transaction"])},
      "run_cron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Cron"])},
      "email_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Reminder"])}
    }
  },
  "account": {
    "transaction": {
      "transaction_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction List"])},
      "payment_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment ID"])},
      "reference_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])},
      "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
      "sale_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Amount"])},
      "paid_payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status: Paid"])},
      "payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
      "system_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Status"])},
      "payment_purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Purpose"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "date_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])},
      "date_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Payment"])},
      "reference_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference 1"])},
      "reference_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference 2"])},
      "date_from_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date From To"])},
      "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Type"])}
    },
    "report": {
      "reconcilation_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconcilation Report"])},
      "payment_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment ID"])},
      "gross_commision_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Commission Report"])},
      "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
      "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
      "business_purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Purpose"])},
      "sale_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Amount"])},
      "charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges"])},
      "net_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "filter_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])}
    }
  },
  "payout": {
    "table": {
      "account": {
        "tab_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested "])},
        "tab_settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
        "tab_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Action"])},
        "tab_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])},
        "tab_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Transaction"])},
        "tab_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed Transaction"])},
        "header_date_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Date "])},
        "header_date_processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Date"])},
        "header_date_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Action Date"])},
        "header_date_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned Date"])},
        "header_date_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Transaction Date"])},
        "header_bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
        "header_acc_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
        "header_acc_holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder"])},
        "header_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "header_business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])},
        "header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "header_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "header_remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
        "resolved_by_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved by merchant"])},
        "unresolved_by_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unresolved by merchant"])}
      },
      "merchant": {
        "tab_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested "])},
        "tab_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
        "tab_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Action"])},
        "tab_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Transaction"])},
        "tab_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])}
      },
      "settlement_history": {
        "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])},
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
        "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested"])}
      }
    },
    "modal": {
      "download_csv_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download .CSV format"])},
      "download_csv_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the pending completion after transfer the settlement to the destinated bank account."])},
      "download_csv_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
      "csv_download_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CSV file will be sent to your registered email. Kindly check your Inbox."])},
      "insert_ref_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert reference ID"])},
      "upload_proof_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload proof documents"])},
      "upload_proof_documents_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload .PDF, .JPG, .PNG Format not more than 10MB"])},
      "proceed_to_settlement_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to settlement?"])},
      "proceed_to_settlement_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to settlement"])},
      "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])},
      "proceed_settlement_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to proceed this request to settlement?"])},
      "mark_as_fail_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked as failed"])},
      "marked_as_failed_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as failed?"])},
      "mark_as_fail_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
      "mark_as_transferred_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marked as transferred"])},
      "return_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to merchant"])},
      "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
      "edit_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit issue"])},
      "resolved_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved pending actions?"])},
      "resolved_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By resolving this pending actions, our team will review back your payout request and returned back to your account if there’s no issue found. "])},
      "mark_as_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as resolved"])},
      "pending_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending action"])}
    },
    "payment_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlement List"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "update_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update status"])},
    "upload_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload .PDF, .JPG, .PNG Format not more than 10MB."])},
    "upload_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload documents"])},
    "transferred_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred"])},
    "download_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed download CSV"])},
    "alert_transferred_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred Completed!"])},
    "alert_pending_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Action"])}
  },
  "support": {
    "payment_link_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Payment"])},
    "contact_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact no"])},
    "currency_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "business_support_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business support email"])},
    "payment_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment id"])},
    "payment_link_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment ID"])},
    "payment_attempt_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment attempt id"])},
    "ref_1_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference 1"])},
    "ref_2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference 2"])},
    "payout": {
      "tab_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested "])},
      "tab_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
      "tab_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Action"])},
      "tab_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Transaction"])},
      "tab_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])}
    }
  },
  "subscription": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions Menu"])},
    "active_your_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a subscription package."])},
    "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Package"])},
    "tab_subscriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subscriber"])},
    "tab_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package List"])},
    "tab_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "package_list_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Subscription Package"])},
    "subscriber": {
      "add_subscriber_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subscriber & Package"])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of subscriber"])},
      "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address of subscriber"])},
      "phone_no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number (Optional)"])},
      "phone_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number of subscriber"])},
      "choose_package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose package"])},
      "package_for_subscriber_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package for subscriber"])},
      "choose_package_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose or add your package"])},
      "submit_subscription_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Subscription Plan"])},
      "preview_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "preview_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide preview"])},
      "preview_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show preview"])},
      "create_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new package"])},
      "package_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package List"])},
      "add_subscriber_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add subscriber for this package"])},
      "list_of_subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of Subscribers :"])},
      "change_package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose New Package"])},
      "change_package_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose package"])},
      "change_package_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Package"])},
      "confirm_change_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Package for Subscriber"])},
      "confirm_change_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send email to the subscriber for confirmation of changing subscription package."])},
      "confirm_change_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation email has been sent to user."])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel The Subscription?"])},
      "cancel_active_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not receive any payment from this subscriber. We will notify subscriber by email."])},
      "cancel_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is not active yet? Are you sure want to cancel his/her subscription?"])},
      "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Subscription"])},
      "copy_activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy activation link"])},
      "status": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "to_be_change_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Change Package"])},
        "to_be_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Cancellation"])},
        "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])},
        "deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactive"])},
        "update_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to Update Card"])}
      },
      "empty": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subscribers"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription package will be use to create your subscription link. Let’s start with create one subscription package"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package"])}
      }
    },
    "activation": {
      "subscription_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Details"])},
      "subscription_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
      "subscription_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
      "subscription_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])}
    },
    "detail": {
      "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "status_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "last_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last payment"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next payment"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
      "payment_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment history"])},
      "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
      "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
      "retry_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry payment"])},
      "payment_status": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment attempted"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment pending"])},
        "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached Thank You Page"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment succeeded"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment error"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned"])}
      }
    },
    "redirect": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You For Subscribing!"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have successfully subscribed <b>", _interpolate(_named("package_name")), "</b>. Here are the details of the subscription."])},
      "pending_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your subscription <b>", _interpolate(_named("package_name")), "</b> is still pending activation. Here are the details of the subscription."])},
      "subscribed_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have subscribed to:"])},
      "subscription_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Name: "])},
      "subscription_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Plan: "])},
      "subscription_date_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Started: "])},
      "subscription_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Price: "])},
      "change_package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Change Subscription"])},
      "change_package_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have successfully change your subscription to <b>", _interpolate(_named("package_name")), "</b>. Here are the details of the new subscription."])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Cancel Subscription"])},
      "cancel_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have confirm your <b>", _interpolate(_named("package_name")), "'s</b> subscription cancellation. Here are the details of the new subscription."])},
      "close_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may close this window"])}
    },
    "create_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package"])},
    "table_header": {
      "edit_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Package"])},
      "delete_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete package"])},
      "delete_package_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? This process cannot be undone."])},
      "package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
      "package_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Description"])},
      "package_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Amount"])},
      "package_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Frequency"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "subscriber_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "subscriber_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "subscriber_phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No subscription package"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription package will be use to create your subscription link. Let’s start with create one subscription package"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package"])}
    },
    "package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
    "package_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name of subscription"])},
    "amount_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of package"])},
    "package_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package description"])},
    "package_description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package description"])},
    "amount_of_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of package"])},
    "package_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Frequency"])},
    "date_charge_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date charge start"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "amount_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
    "package_frequency_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package frequency number"])},
    "start_after_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start after type"])},
    "start_after_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start after number"])}
  },
  "customer": {
    "customer_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers Menu"])},
    "active_your_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a customer."])},
    "customer_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer List"])},
    "add_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Customer"])},
    "add_a_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a customer"])},
    "add_new_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Customer"])},
    "add_additional_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add additional details"])},
    "search_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Customer"])},
    "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
    "customer_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide customer name"])},
    "customer_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Email"])},
    "customer_phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Phone Number"])},
    "customer_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer email address"])},
    "invoice_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Prefix"])},
    "invoice_prefix_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state"])},
    "invoice_sequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Sequence"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
    "shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "same_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as billing address"])},
    "delete_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Customer"])},
    "delete_customer_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? this process can not be undone."])},
    "no_customer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no customer found"])},
    "no_customer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customer found"])},
    "table_header": {
      "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "customer_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "customer_phone_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "customer_date_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
    },
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customer created"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill customers with one-off or recurring invoices, or subscriptions. Ready to add one?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Customer"])}
    }
  },
  "product": {
    "product_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product List"])},
    "active_your_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create a product."])},
    "add_a_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a product"])},
    "add_new_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new product"])},
    "search_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Product"])},
    "no_product_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no product found"])},
    "no_product_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No product found"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilogram"])},
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gram"])},
    "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centimeter"])},
    "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meter"])},
    "menu": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Menu"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product List"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
    },
    "filter": {
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "curency_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "price_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
    },
    "table_header": {
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Image"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])},
      "date_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])}
    },
    "modal": {
      "add_product_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])},
      "edit_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])},
      "image_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Image"])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
      "price_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])},
      "unit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit of Measure"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name to display"])},
      "name_placeholder_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
      "price_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.00"])},
      "unit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select unit of measure"])},
      "unit_placeholder_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per"])},
      "unit_placeholder_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
      "preview_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "delete_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Product"])},
      "delete_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? This process cannot be undone."])}
    },
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No product created"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products that you sell to your customers. It can be anything from physical goods to digital services or subscription plans. Ready to create one?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Product"])}
    }
  },
  "tax": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Menu"])},
    "active_your_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be activated before you can create tax."])},
    "add_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])},
    "edit_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Tax"])},
    "tab_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax List"])},
    "tab_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "tax_list_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax List"])},
    "btn_add_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new tax"])},
    "no_tax_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no tax found"])},
    "no_tax_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tax found"])},
    "attach_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])},
    "attach_tax_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding tax is optional"])},
    "add_new_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Tax"])},
    "type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tax is:"])},
    "select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Tax"])},
    "select_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or add new tax"])},
    "table_header": {
      "tax_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Name"])},
      "tax_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "tax_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate (%)"])},
      "tax_date_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Created"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
    },
    "filter": {
      "placeholder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "placeholder_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
      "placeholder_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
    },
    "form_add_tax": {
      "label_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Name"])},
      "label_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Rate"])},
      "label_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "label_btn_add_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])},
      "label_btn_save_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "placeholder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide tax name"])},
      "placeholder_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "placeholder_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly explain about your tax rate"])}
    },
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tax created"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax that will be use along when created invoice. It can be anything. Ready to create one?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Tax"])}
    },
    "types": {
      "inclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusive"])},
      "exclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "delete_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Tax"])},
    "delete_tax_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? this process can not be undone."])}
  },
  "invoice": {
    "invoice_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice List"])},
    "create_new_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new invoice"])},
    "no_invoice_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops no invoice found"])},
    "no_invoice_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoice found"])},
    "menu": {
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Menu"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice List"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting"])},
      "aging_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging Reporting"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
    },
    "filter": {
      "customer_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])}
    },
    "table_header": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Status"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No."])},
      "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
      "customer_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Email"])},
      "due_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Due"])},
      "due_amount_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
      "due_date_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Due Date"])},
      "aging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging (Day)"])},
      "tab_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Invoices"])},
      "tab_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "tab_unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
      "tab_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])}
    },
    "create": {
      "create_invoice_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Invoice"])},
      "create_invoice_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Invoice"])},
      "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as draft"])},
      "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice"])},
      "send_invoice_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create and send invoice for ", _interpolate(_named("amount")), " to ", _interpolate(_named("customer")), "?"])},
      "uneditable_invoice_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice can't be edited after they are sent"])},
      "edit_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit invoice"])},
      "continue_editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue editing"])},
      "customer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "product_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "product_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prod. Image"])},
      "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "product_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "product_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
      "product_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "product_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
      "tax_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Tax"])},
      "add_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tax"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
      "memo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add some memo for your customer"])},
      "internal_memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Memo"])},
      "internal_memo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add some memo for yourself (this memo will not appear on the invoice)"])},
      "payment_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Term"])},
      "payment_term_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
      "terms": {
        "0d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "1d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])},
        "7d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 Days"])},
        "14d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 Days"])},
        "30d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 Days"])},
        "45d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45 Days"])},
        "60d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60 Days"])},
        "90d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90 Days"])},
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Date"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "types": {
        "one_time_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Payment"])},
        "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring"])}
      },
      "allow_partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow partial payment"])},
      "custom_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Fields"])},
      "custom_attributes_key_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name"])},
      "custom_attributes_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field value"])},
      "add_new_custom_attribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new custom field"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer"])},
      "footer_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add some footer for your customer"])},
      "preview_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "preview_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide preview"])},
      "preview_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show preview"])}
    },
    "detail": {
      "invoice_no": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invoice No: ", _interpolate(_named("no"))])},
      "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])},
      "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Paid"])},
      "amount_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Due"])},
      "status_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "status": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Created"])},
        "created_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Sent"])},
        "open_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Paid"])},
        "paid_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
        "paid_with_amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invoice Paid (", _interpolate(_named("amount")), ")"])},
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Overdue"])},
        "overdue_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
        "void": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Void"])},
        "void_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Void"])},
        "uncollectible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Uncollectible"])},
        "uncollectible_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncollectible"])}
      },
      "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
      "due_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Due in ", _interpolate(_named("n")), " days"])},
      "due_in_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Days"])},
      "payment_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Due"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice History"])},
      "invoice_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Details"])},
      "payment_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment term"])},
      "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "customer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Details"])},
      "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
      "customer_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "customer_billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
      "customer_shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping address"])},
      "customer_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "product_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Details"])},
      "product_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Photo"])},
      "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
      "product_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])},
      "product_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Label"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])}
    },
    "invoice_pdf": {
      "preview_tab_invoice_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice PDF"])},
      "invoice_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice No"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
      "amount_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "total_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Due"])},
      "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
      "shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])}
    },
    "report": {
      "aging_report_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging Report Summary"])},
      "aging_report_title_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aging Report"])},
      "as_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["As of ", _interpolate(_named("time"))])},
      "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Summary (", _interpolate(_named("currency")), ")"])},
      "total_outstanding": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total Outstanding Invoice: ", _interpolate(_named("outstanding"))])},
      "n_outstanding": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " Outstanding Invoices"])},
      "within_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Within ", _interpolate(_named("from")), " - ", _interpolate(_named("to")), " days"])},
      "over_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Over ", _interpolate(_named("from")), " days"])},
      "list_summary_within": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " of ", _interpolate(_named("total")), " of aging report within ", _interpolate(_named("from")), " - ", _interpolate(_named("to")), " days"])},
      "list_summary_over": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " of ", _interpolate(_named("total")), " of aging report over ", _interpolate(_named("from")), " days"])},
      "temp_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Notes"])},
      "temp_notes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your note here..."])},
      "delete_temp_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Temporary Notes"])},
      "delete_temp_notes_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this temporary notes?"])}
    },
    "action": {
      "download_as_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download as PDF"])},
      "edit_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Invoice"])},
      "view_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Invoice"])},
      "delete_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Invoice"])},
      "delete_invoice_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this? This process cannot be undone."])},
      "duplicate_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Invoice"])},
      "resend_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Invoice"])},
      "change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Invoice Status"])},
      "add_temporary_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Temporary Notes"])},
      "export_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export As"])},
      "export_all_as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Export all as ", _interpolate(_named("format"))])},
      "export_selection_as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Export selection as ", _interpolate(_named("format"))])}
    }
  }
}