<template>
  <app-modal class="rounded-md" :showCancelButton="false">
    <template v-slot:body>
      <div ref="capture" class="bg-white">
        <div class="flex flex-col items-center pt-14 pb-8">
          <!--<img
            v-if="businessLogo"
            :src="businessLogo"
            class="w-24 object-cover"
          />-->
          <p class="text-black">
            {{ business.name ?? "-" }}
          </p>
        </div>
        <div class="flex flex-col items-center">
          <div>
            <qrcode-vue :value="model.payment_url" :size="size" level="H" />
          </div>
          <div class="m-2">
            {{ $t("payment.open-payment-link.qrcode-page.scan_to_pay") }}
          </div>

          <!-- Logo powered by payje -->
          <div class="p-8">
            <app-logo-powered-by-payje-dark />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:action>
      <div class="flex flex-row space-x-2">
        <div>
          <app-button-outline
            class="bg-white"
            padding="px-4 py-1"
            @click="copyUrl(model)"
          >
            <app-icon-outline
              name="DocumentDuplicateIcon"
              class="h-5 w-5 text-gray-400"
            />
            <p class="ml-1 text-xs">
              {{ $t("payment.detail.copy_link") }}
            </p>
          </app-button-outline>
        </div>
        <div>
          <app-button-outline class="bg-white" padding="px-4 py-1">
            <p class="ml-1 text-xs" @click="onCapture">
              {{ $t("payment.open-payment-link.qrcode-page.download_image") }}
            </p>
          </app-button-outline>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import MEDIA_CATEGORY from "@/utils/const/media_category";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      size: 200,
      image: null,
    };
  },

  props: {
    model: {
      type: Object,
      default: null,
    },
  },

  components: {
    QrcodeVue,
  },
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
    businessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.LOGO
      )?.original_url;
    },
  },

  methods: {
    onCapture() {
      html2canvas(this.$refs.capture).then((canvas) => {
        const image = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");

        const a = document.createElement("a");

        a.setAttribute("download", "download-qr.png");
        a.setAttribute("href", image);
        a.click();

        canvas.remove();
      });
    },
    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },
  },
};
</script>