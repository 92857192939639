import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class PaymentRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async index(business_id, queryParams) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/payment-links" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getListCommission(queryParams) {
    const response = await client.get(
      "/reporting/gross-commission" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getTransactionList(queryParams) {
    const response = await client.get(
      "/reporting/transactions" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async downloadCommissionCSV(queryParams) {
    const response = await client.get(
      "reporting/gross-commission/export-excel" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }
    return this.successResponse(response.data);
  }

  async downloadTransactionList(queryParams) {
    
    const response = await client.get(
      "reporting/transactions/export-excel" + queryParams,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }
    return this.successResponse(response.data);
  }

  async downloadCommissionPdf(queryParams) {
      const response = await client.get(
        "reporting/gross-commission/export-pdf" + queryParams,
        this.getToken(),
      );

      if (this.$lodash.isEmpty(response.data)) {
        return response;
      }

      return this.successResponse(response.data);
  }

  async indexMasterBusiness(masterBusiness_id, filtersUrl) {
    const response = await client.get(
      "/payments/master-businesses/" + masterBusiness_id + "/payment-links",
      this.getToken(),
      filtersUrl
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdate(business_id, payment_link) {
    var to_submit_payment_link = this.$lodash.omitBy(
      payment_link,
      this.$lodash.isNil
    );

    const response =
      payment_link.id == null
        ? await client.post(
          "/payments/businesses/" + business_id + "/payment-links",
          to_submit_payment_link,
          this.getToken()
        )
        : await client.put(
          "/payments/businesses/" +
          business_id +
          "/payment-links/" +
          payment_link.id,
          to_submit_payment_link,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateMasterBusiness(masterBusiness_id, payment_link) {
    var to_submit_payment_link = this.$lodash.omitBy(
      payment_link,
      this.$lodash.isNil
    );

    const response =
      payment_link.id == null
        ? await client.post(
          "/payments/master-businesses/" + masterBusiness_id + "/payment-links",
          to_submit_payment_link,
          this.getToken()
        )
        : await client.put(
          "/payments/master-businesses/" + masterBusiness_id + "/payment-links/" + payment_link.id,
          to_submit_payment_link,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentLinkPublic(payment_link_id) {
    const response = await client.get(
      "/payments/payment-links/" + payment_link_id
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentLink(business_id, payment_link_id) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/payment-links/" + payment_link_id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getOpenPaymentLinkPublic(open_payment_link_id) {
    const response = await client.get(
      "/payments/open-payment-links/" + open_payment_link_id
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentLinkMasterBusiness(masterBusiness_id, business_id) {
    const response = await client.get(
      "/payments/master-businesses/" + masterBusiness_id + "/businesses/" + business_id + "/payment-links"
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async resendEmail(business_id, payment_link_id) {

    const response = await client.post("/payments/businesses/" + business_id + "/payment-links/" + payment_link_id + "/resend-email",
      [],
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async resendEmailMasterBusiness(master_business_id, payment_link_id) {

    const response = await client.post("/payments/master-businesses/" + master_business_id + "/payment-links/" + payment_link_id + "/resend-email",
      [],
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }


  async getBusinessPaymentGatewayMethod(business_id) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/payment-gateway-methods",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateStatusBusinessPaymentGatewayMethod(
    business_id,
    payment_gateway_method
  ) {
    const response =
      payment_gateway_method._id == null
        ? await client.post(
          "/payments/businesses/" + business_id + "/payment-gateway-methods",
          payment_gateway_method,
          this.getToken()
        )
        : await client.put(
          "/payments/businesses/" +
          business_id +
          "/payment-gateway-methods/" +
          payment_gateway_method._id,
          payment_gateway_method,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentGatewayApplications(business_id) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/payment-gateway-applications",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdatePaymentGatewayApplication(
    business_id,
    payment_gateway_application
  ) {
    const response =
      payment_gateway_application._id == null
        ? await client.post(
          "/payments/businesses/" +
          business_id +
          "/payment-gateway-applications",
          payment_gateway_application,
          this.getToken()
        )
        : await client.put(
          "/payments/businesses/" +
          business_id +
          "/payment-gateway-applications/" +
          payment_gateway_application._id,
          payment_gateway_application,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentMethods(business_id) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/payment-methods"
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getChargesType() {
    const response = await client.get(
      "/payments/payment-gateway-methods/all-charges-type",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async proceedPaymentAttempt(
    payment_link_id,
    business_payment_gateway_method_id,
    card_info,
    bank_info,
    stripe = null
  ) {
    const response = await client.post(
      "/payments/payment-links/" + payment_link_id + "/attempts",
      {
        business_payment_gateway_method_id: business_payment_gateway_method_id,
        card_name: (card_info != null && card_info.card_name != null) ? card_info.card_name : null,
        card_number: (card_info != null && card_info.card_number != null) ? card_info.card_number.trim() : null,
        expiration_year: (card_info != null && card_info.card_expiry != null) ? "20" + card_info.card_expiry.split("/")[1] : null,
        expiration_month: (card_info != null && card_info.card_expiry != null) ? card_info.card_expiry.split("/")[0] : null,
        cvc: (card_info != null && card_info.card_cvc != null) ? card_info.card_cvc: null,
        bank_code: (bank_info != null && bank_info.code != null) ? bank_info.code: null,
        stripe: stripe
      }
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async proceedOpenPaymentAttempt(
    open_payment_link_id,
    business_payment_gateway_method_id,
    email,
    name,
    phone,
    amount,
    card_info,
    bank_info,
    stripe
  ) {
    const response = await client.post(
      "/payments/open-payment-links/" + open_payment_link_id + "/attempts",
      {
        business_payment_gateway_method_id: business_payment_gateway_method_id,
        email: email,
        name: name,
        phone: phone,
        amount: amount,
        card_name: (card_info != null && card_info.card_name != null) ? card_info.card_name : null,
        card_number: (card_info != null && card_info.card_number != null) ? card_info.card_number.trim() : null,
        expiration_year: (card_info != null && card_info.card_expiry != null) ? "20" + card_info.card_expiry.split("/")[1] : null,
        expiration_month: (card_info != null && card_info.card_expiry != null) ? card_info.card_expiry.split("/")[0] : null,
        cvc: (card_info != null && card_info.card_cvc != null) ? card_info.card_cvc: null,
        bank_code: (bank_info != null && bank_info.code != null) ? bank_info.code: null,
        stripe: stripe
      }
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPaymentLinkForSupport(queryParams) {
    const response = await client.get(
      "payments/payment-links" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return this.successResponse(response.data);
  }

  async getOnlineBankByBusiness(business_id, payment_method_id) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/payment-methods/" + payment_method_id + "/all-banks"
    );


    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async requery(payment_link, attempt_id) {

    const response =
      await client.post(
        "/payments/businesses/" + payment_link.business_id + "/payment-links/" +
        payment_link._id + "/payment-attempts/" + attempt_id + "/requery",
        [],
        this.getToken()
      );
    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async indexPaymentGatewayConfig(businessId, paymentGatewayId, channel) {

    const response = await client.get(
      `/payments/businesses/${businessId}/payment-gateways/${paymentGatewayId}/channels/${channel}/configs`,
      this.getToken(),
    );


    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async requeryMasterBusiness(master_business_id, payment_link, attempt_id) {

    const response =
      await client.post(
        "/payments/master-businesses/" + master_business_id + "/payment-links/" +
        payment_link._id + "/payment-attempts/" + attempt_id + "/requery",
        [],
        this.getToken()
      );
    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdatePaymentGatewayConfig(
    businessId,
    paymentGatewayId,
    channel,
    businessPaymentGatewayConfig
  ) {
    const response =
      businessPaymentGatewayConfig._id == null
        ? await client.post(
          `/payments/businesses/${businessId}/payment-gateways/${paymentGatewayId}/channels/${channel}/configs`,
          businessPaymentGatewayConfig,
          this.getToken()
        )
        : await client.put(
          `/payments/businesses/${businessId}/payment-gateways/${paymentGatewayId}/channels/${channel}/configs/${businessPaymentGatewayConfig._id}`,
          businessPaymentGatewayConfig,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async applyForCC(business_id) {

    const response = await client.post("/payments/businesses/" + business_id + "/apply-cc",
      [],
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async connectStripeAccount(business_id, payment_gateway_application_id) {
    const response = await client.post("/payments/businesses/" + business_id + "/payment-gateway-applications/" + payment_gateway_application_id + "/connect-stripe",
      [],
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async statusConnectStripeAccount(business_id, payment_gateway_application_id) {
    const response = await client.get("/payments/businesses/" + business_id + "/payment-gateway-applications/" + payment_gateway_application_id + "/status-connect-stripe",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async openPaymentLinkIndex(business_id, filtersUrl) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/open-payment-links",
      this.getToken(),
      filtersUrl
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async openPaymentLinkIndexMasterBusiness(masterBusiness_id, filters) {
    const response = await client.get(
      "/payments/master-businesses/" + masterBusiness_id + "/open-payment-links",
      this.getToken(),
      filters
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async openPaymentLinkPaymentLinkIndex(business_id, open_payment_link_id, filtersUrl) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/open-payment-links/" + open_payment_link_id + "/payment-links" + filtersUrl,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async openPaymentLinkCreateOrUpdate(business_id, open_payment_link) {
    var to_submit_open_payment_link = this.$lodash.omitBy(
      open_payment_link,
      this.$lodash.isNil
    );

    const response =
      open_payment_link._id == null
        ? await client.post(
          "/payments/businesses/" + business_id + "/open-payment-links",
          to_submit_open_payment_link,
          this.getToken()
        )
        : await client.put(
          "/payments/businesses/" +
          business_id +
          "/open-payment-links/" +
          open_payment_link._id,
          to_submit_open_payment_link,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async openPaymentLinkUpdateStatus(business_id, open_payment_link_id, status_id) {

    const response = await client.patch(
      "/payments/businesses/" + business_id + "/open-payment-links/" +
      open_payment_link_id + "/update-status",
      {
        status_id: status_id
      },
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async openPaymentLinkUpdateStatusMasterBusiness(masterBusiness_id, open_payment_link_id, status_id) {

    const response = await client.patch(
      "/payments/businesses/" + masterBusiness_id + "/open-payment-links/" +
      open_payment_link_id + "/update-status",
      {
        status_id: status_id
      },
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getOpenPaymentLink(open_payment_link_id) {
    const response = await client.get(
      "/payments/open-payment-links/" + open_payment_link_id
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async totalPaid(business_id) {
    const response = await client.get(
      "/payments/businesses/" + business_id + "/total-payment-paid",
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }
    return this.successResponse(response.data);
  }

  async totalPaidMasterBusiness(business_id) {
    const response = await client.get(
      "/payments/master-businesses/" + business_id + "/total-payment-paid",
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }
    return this.successResponse(response.data);
  }

  async cronPayout() {
    const response = await client.post(
      "/admin/cron/payout",
      [],
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }
    return this.successResponse(response.data);
  }

  async cronSettlement() {
    const response = await client.post(
      "/admin/cron/settlement",
      [],
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data))  {
      return this.errorResponse(response);
    }
    return this.successResponse(response.data);
  }

  async cronEmailReminder() {
    const response = await client.post(
      "/admin/cron/reminderemail",
      [],
      this.getToken()
    );

    if (!response?.data && this.$lodash.isEmpty(response.data))  {
      return this.errorResponse(response);
    }
    return this.successResponse(response.data);
  }
}
