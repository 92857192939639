import AuthRepository from "../../data/repository/auth-repository";
import PaymentRepository from "../../data/repository/payment-repository";
import baseStore from "../base-store";
import { logEvent } from "firebase/analytics";

const initialPaymentLink = {
  _id: null,
  id: null,
  email: null,
  phone_no: "",
  title: null,
  currency: "MYR",
  description: null,
  ref1: null,
  ref2: null,
  redirect_url: null,
  send_email: false,
  business_id: null,
};

export default {
  namespaced: true,
  state: {
    ...baseStore.state,

    payment_link: {},
    list_payment_link: [],
  },

  getters: {
    ...baseStore.getters,

    payment_link(state) {
      return state.payment_link;
    },

  },

  mutations: {
    ...baseStore.mutations,
   
    initPaymentLink(state, payment_link = initialPaymentLink) {
      state.payment_link = this.$lodash.clone(payment_link);
    },
    setPaymentLink(state, payment_link) {
      state.payment_link = payment_link;
    },
  },

  actions: {
    async retrievePaymentLinks(state, filters) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const master_business_id = authRepository.getSelectedRole()?.business_id;

      if (master_business_id == null) {
        return;
      }

      state.commit("setLoading", true);
      const paymentLinkIndexResult = await paymentRepository.indexMasterBusiness(
        master_business_id,
        filters
      );

      if (paymentLinkIndexResult.message != undefined) {
        logEvent(this.$analytics, "master-business-payment-link-index", { result: "failed" });
        state.commit("setErrorMessage", paymentLinkIndexResult);
        return;
      } else {
        logEvent(this.$analytics, "master-business-payment-link-index", { result: "success" });
      }

      state.commit("setLoading", false);
      state.commit("setApiPaginationResponse", paymentLinkIndexResult);
      return paymentLinkIndexResult;
    },

    async createOrUpdatePaymentLinkMasterBusiness(state) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const masterBusiness_id = authRepository.getSelectedRole()?.business_id;

      if (masterBusiness_id == null) {
        return;
      }

      state.commit("setLoading", true);

      Object.keys(state.getters.payment_link).forEach((key) => {
        if (state.getters.payment_link[key] === "") {
          delete state.getters.payment_link[key];
        }
      });

      var paymentLink = state.getters.payment_link
      paymentLink.business_id = state.getters.payment_link.business?.id

      const paymentLinkResult = await paymentRepository.createOrUpdateMasterBusiness(
        masterBusiness_id,
        paymentLink
      );

      const isCreate = state.getters.payment_link.id == null;

      if (paymentLinkResult.message != undefined) {
        logEvent(
          this.$analytics,
          isCreate ? "master-business-create-payment-link" : "update-payment-link",
          { result: "failed" }
        );
        state.commit("setErrorMessage", paymentLinkResult);
        return;
      } else {
        logEvent(
          this.$analytics,
          isCreate ? "master-business-create-payment-link" : "update-payment-link",
          { result: "success" }
        );
      }

      state.commit("setErrorMessage");
      state.commit("setLoading", false);

      return paymentLinkResult;
    },

    async getPaymentLinkMasterBusiness(state, paymentLinkId) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const masterBusiness_id = authRepository.getSelectedRole()?.business_id;

      state.commit("setLoading", true);

      const paymentLinkResult = await paymentRepository.getPaymentLinkMasterBusiness(
        masterBusiness_id, paymentLinkId
      );

      if (paymentLinkResult.message != undefined) {
        logEvent(this.$analytics, "master-business-get-payment-link", { result: "failed" });
        state.commit("setErrorMessage", paymentLinkResult);
        return;
      } else {
        logEvent(this.$analytics, "master-business-get-payment-link", { result: "success" });
      }

      state.commit("setPaymentLink", paymentLinkResult.data);
      state.commit("setLoading", false);
      return paymentLinkResult;
    },

    async resendEmailMasterBusiness(state, paymentLinkId) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const master_business_id = authRepository.getSelectedRole()?.business_id;

      if (master_business_id == null) {
        return;
      }

      const resendEmailResult = await paymentRepository.resendEmailMasterBusiness(
        master_business_id,
        paymentLinkId
      );

      if (resendEmailResult.message != undefined) {
        logEvent(this.$analytics, "resend-email-payment-link", {
          result: "failed",
        });
        state.commit("setErrorMessage", resendEmailResult);
        return;
      } else {
        logEvent(this.$analytics, "resend-email-payment-link", {
          result: "success",
        });
      }

      return resendEmailResult;
    },

    async requeryMasterBusiness(state, params) {
      const paymentRepository = new PaymentRepository();
      const authRepository = new AuthRepository();

      const payment_link = params.payment_link;
      const attempt_id = params.attempt_id;
      const master_business_id = authRepository.getSelectedRole()?.business_id;

      if (payment_link == null || attempt_id == null || master_business_id == null) {
        return;
      }

      const requeryResult =
        await paymentRepository.requeryMasterBusiness(master_business_id, payment_link, attempt_id);

      if (requeryResult.message != undefined) {
        logEvent(this.$analytics, "requery", {
          result: "failed",
        });
        state.commit("setErrorMessage", requeryResult);
        return;
      } else {
        logEvent(this.$analytics, "requery", {
          result: "success",
        });
      }

      state.commit("setRequery", requeryResult);
      return requeryResult;
    },
  },
};
