<template>
  <div :key="componentKey" >
    <div class="flex flex-row z-0">
      <app-button-border-bottom
        v-for="item in tabs"
        :key="item.key"
        :active="isCurrentTabName(item.key)"
        @onClick="onTabClick(item.key)"
        class="capitalize"
      >
        {{ item.title }}
      </app-button-border-bottom>
      <span v-if="tabs && length > 0" class="flex-1 border-b-2 w-full"></span>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  emits: ['tab-changed'],
  data() {
    return { componentKey: 0 };
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    selected_tab:{
      type: [String, Number],
      default: '',
    }
  },
  watch: {
    selected_tab(tab_name) {
      return this.onTabClick(tab_name);
    },
  },
  methods: {
    isCurrentTabName(tab_name) {
      if (this.$route.query.tab == null && this.tabs[0]?.key == tab_name) {
        return true;
      }
      return this.$route.query.tab == tab_name;
    },
    onTabClick(tab_name) {
      this.$route.query.tab = tab_name;
      this.$emit("tab-changed", tab_name);
      this.forceRerender();
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>