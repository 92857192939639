<template>
  <div
    class="
      py-1
      px-3
      rounded-md
      text-center
      flex
      place-items-center
      justify-center
      capitalize
      text-xs
    "
    :class="[
      {
        'bg-success-badge text-green-900':
          status == 'success',
        'bg-error-badge text-red-900': status == 'error',
        'bg-warning-badge text-yellow-900'  :
          status == 'warning',
        'bg-light-badge text-gray-900':
          status == 'light',
      },
      $attrs.class,
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    status: String,
  },
};
</script>