<template>
  <app-modal class="rounded-md" :showCancelButton="false">
    <template v-slot:title>
      <div class="flex justify-between text-base">
        <p>
          {{
            this.packages?._id != null
              ? $t("subscription.edit_package")
              : $t("subscription.create_package")
          }}
        </p>
      </div>
    </template>

    <template v-slot:body>
      <div class="space-y-6">
        <div>
          <app-form-input
            v-model="packages.name"
            type="text"
            :labelText="$t('subscription.package_name')"
            :placeholder="$t('subscription.package_name_placeholder')"
            :errorMessage="errors.name"
          />
        </div>
        <div>
          <app-form-input
            v-model="packages.description"
            type="text"
            :labelText="$t('subscription.package_description')"
            :placeholder="$t('subscription.package_description_placeholder')"
            :errorMessage="errors.description"
          />
        </div>
        <div>
          <app-form-input-with-select
            v-model="packages.amount"
            type="number"
            step=".01"
            :labelText="$t('subscription.amount_of_package')"
            :placeholder="$t('payment.create.amount_placeholder')"
            :errorMessage="errors.amount"
            @input="validateAmount"
            maxLength="7"
            v-model:option="packages.currency"
            :options="[
              {
                id: 1,
                desc: 'MYR',
              },
            ]"
          />
        </div>
        <div>
          <app-form-select
            class="w-full"
            :datas="packageFrequencies"
            :labelText="$t('subscription.package_frequency')"
            :errorMessage="errors.frequency"
            :loading="subscriptionStore/loading"
            :allowFilter="false"
            v-model="packages.frequency_selected"
            v-on:retry="getFrequency"
          />
        </div>
        <div>
          <app-form-input
            v-model="packages.frequency_num"
            type="number"
            inputclass="rounded-t-md"
            :labelText="$t('subscription.package_frequency_number')"
            :placeholder="$t('subscription.amount_placeholder')"
            :errorMessage="errors.frequency_num"
          />
        </div>
        <div>
          <app-form-select
            class="w-full"
            :datas="packageStartAfterType"
            :labelText="$t('subscription.start_after_type')"
            :errorMessage="errors.start_after_type"
            :loading="subscriptionStore/loading"
            :allowFilter="false"
            v-model="packages.start_after_selected"
            v-on:retry="getStartAfterType"
          />
        </div>
        <div>
          <app-form-input
            v-model="packages.start_after_num"
            type="number"
            inputclass="rounded-t-md"
            :labelText="$t('subscription.start_after_number')"
            :placeholder="$t('subscription.amount_placeholder')"
            :errorMessage="errors.start_after_num"
          />
        </div>
        <div>
          <app-alert-error
            v-if="errors.business_id"
            :message="errors.business_id[0]"
          />
        </div>
      </div>
    </template>

    <template v-slot:action>
      <app-button
        class="w-full"
        @click="onClickCreatePackage"
        :showf70Icon="false"
        :disabled="hasActiveBusiness"
      >
        {{
          this.packages?._id != null
            ? $t("subscription.done")
            : $t("subscription.create_package")
        }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import appAlertError from "../alert/app-alert-error.vue";
import Business_status from "@/utils/const/business_status";
export default {
  components: { appAlertError },
  data() {
    return {
      maxAmount: 30000,
      minAmount: 5,
    };
  },

  props: {},

  mounted() {
    this.$store.dispatch("subscriptionStore/retrieveFrequenciesNames");
    this.$store.dispatch("subscriptionStore/retrieveStartAfterType");
  },

  computed: {
    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },
    errors() {
      return this.$store.getters["subscriptionStore/errors"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }
      return this.business.business_status_id != Business_status.APPROVED;
    },
    packageFrequencies() {
      return this.$store.getters["subscriptionStore/packageFrequencies"];
    },
    packageStartAfterType() {
      return this.$store.getters["subscriptionStore/packageStartAfterType"];
    },
    packages() {
      return this.$store.getters["subscriptionStore/package"];
    },
  },
  methods: {
    validateAmount(event) {
      const amount = parseFloat(event.target.value.trim());
      if (amount > this.maxAmount) {
        this.errors.amount = [
          this.$t("payment.create.max_amount", {
            max_amount: this.$formats.currency(
              this.packages?.currency,
              this.maxAmount
            ),
          }),
        ];
        return;
      }

      if (amount < this.minAmount) {
        this.errors.amount = [
          this.$t("payment.create.min_amount", {
            min_amount: this.$formats.currency(
              this.packages?.currency,
              this.minAmount
            ),
          }),
        ];
        return;
      }

      this.errors.amount = [];
    },

    getFrequency() {
      this.$store.dispatch("subscriptionStore/retrieveFrequenciesNames");
    },
    getStartAfterType() {
      this.$store.dispatch("subscriptionStore/retrieveStartAfterType");
    },
    async onClickCreatePackage() {
      this.packages.frequency = this.packages.frequency_selected?.name;
      this.packages.start_after_type = this.packages.start_after_selected?.name;

      const result = await this.$store.dispatch(
        "subscriptionStore/createOrUpdatePackage",
        this.packages
      );

      if (this.$lodash.isEmpty(result.errors)) {
        this.$store.dispatch("subscriptionStore/resetPackage");

        this.close();

        this.refresh();

        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },

    close() {
      this.$emit("close");
    },

    refresh() {
      this.$emit("refresh", true);
    },
  },
};
</script>