import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class SubscriptionRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async indexPackage(business_id, filtersUrl) {
    const response = await client.get(
      "/subscriptions/businesses/" + business_id + "/packages" + filtersUrl,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getSubscriberPublic(subscriber_id) {
    const response = await client.get(
      `/subscriptions/subscribers/${subscriber_id}`,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdatePackage(business_id, packages) {
    var to_submit_packages = this.$lodash.omitBy(packages, this.$lodash.isNil);

    const response =
      packages._id == null
        ? await client.post(
          "/subscriptions/businesses/" + business_id + "/packages",
          to_submit_packages,
          this.getToken()
        )
        : await client.put(
          "/subscriptions/businesses/" +
          business_id +
          "/packages/" +
          packages._id,
          to_submit_packages,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async deletePackage(business_id, packages) {
    const response = await client.delete(
      "/subscriptions/businesses/" + business_id + "/packages/" + packages._id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async getPackagesByBusinessId(business_id) {
    const response = await client.get(
      "/subscriptions/businesses/" + business_id + "/packages",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPackageById(business_id, package_id) {
    const response = await client.get(
      "/subscriptions/businesses/" + business_id + "/packages/" + package_id
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async indexSubscriber(business_id, package_id, filters) {
    const response = await client.get(
      "/subscriptions/businesses/ " + business_id + "/packages/" + package_id + "/subscribers" + filters,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPackageFrequencies(business_id) {
    const response = await client.get(
      "/subscriptions/businesses/" + business_id + "/package-frequencies",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPackageStartAfterType(business_id) {
    const response = await client.get(
      "/subscriptions/businesses/" + business_id + "/package-start-after",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateSubscriber(business_id, subscriber) {

    const response =
      subscriber._id == null
        ? await client.post(
          "/subscriptions/businesses/" + business_id + "/packages/" + subscriber.package_id + "/subscribers",
          subscriber,
          this.getToken()
        )
        : await client.put(
          "/subscriptions/businesses/" + business_id +
          "/packages/" + subscriber.package_id +
          "/subscribers/" + subscriber._id,
          subscriber,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getPackagesChangePackage(business_id, package_id) {
    const response = await client.get(
      "/subscriptions/businesses/" + business_id + "/packages/" + package_id + "/change-packages",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async changePackage(business_id, package_id, subscriber_id, new_package_id) {
    const response = await client.patch(
      "/subscriptions/businesses/" + business_id + "/packages/" + package_id
      + "/subscribers/" + subscriber_id + "/change-package",
      {
        package_id: new_package_id
      },
      this.getToken()
    );

    if (response.message != null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async cancelPackage(business_id, package_id, subscriber_id) {
    const response = await client.patch(
      "/subscriptions/businesses/" + business_id + "/packages/" + package_id
      + "/subscribers/" + subscriber_id + "/cancel-package",
      [],
      this.getToken()
    );

    if (response.message != null) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getSubscriberById(business_id, data) {
    const response = await client.get(
      "/subscriptions/businesses/" + business_id +
      "/packages/" + data.package_id +
      "/subscribers/" + data.subscriber_id,
      this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

}
