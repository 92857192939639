<template>
  <app-modal
    class="rounded-md"
    title="Withdraw"
    :backgroundClose="false"
    :showCancelButton="false"
  >
    <template v-slot:title>
      <p>{{ crop_title ?? $t("modal.app_cropper_title") }}</p>
    </template>

    <template v-slot:body>
      <div class="w-full text-center py-2">
        <app-icon-loading iconColor="primary" class="m-auto" v-if="loading" />
        <cropper
          backgroundClass="background"
          foregroundClass="foreground"
          ref="cropper"
          @change="onChange"
          :src="image.src"
          :stencil-props="{
            aspectRatio: aspect_ratio ?? 1,
          }"
        />
      </div>
    </template>

    <template v-slot:action>
      <div class="flex space-x-3">
        <app-button-outline @click="cancel">
          <span>{{ $t("general.cancel") }}</span>
        </app-button-outline>
        <app-button @click="save" :showf70Icon="false">
          <span>{{ crop_button_title ?? this.$t("general.save") }}</span>
        </app-button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  props: {
    image: {
      type: Object,
      default: () => ({
        src: null
      }),
    },
    aspect_ratio: {
      type: Number,
      default: 1.0
    },
    crop_button_title: {
      type: String,
      default: null,
    },
    crop_title: {
      type: String,
      default: null,
    },
  },
  components: {
    Cropper,
  },
  data() {
    return {
      loading: false,
      preview: {
        coordinates: null,
        image: null,
      },
    };
  },
  watch: {
    "image.src"(value) {
      if (value) {
        this.loading = true;
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    cancel() {
      this.close();
      this.$emit("cancel");
    },
    save() {
      this.$emit("preview", this.preview);
      this.$emit("result", this.$refs.cropper.getResult());
      this.close();
    },
    onChange({ coordinates, image }) {
      this.loading = false;
      this.preview = {
        coordinates,
        image,
      };
    },
  },
};
</script>

<style>
.foreground {
  background: #c8c8c8;
}
.background {
  background: white;
}
</style>