// Import Base App
import { createApp } from "vue";
import App from "@/views/app.vue";

// Import Utils File
import router from "@/utils/router";
import locale from "@/utils/locale";
import global from "@/utils/global";
import store from "@/stores/store";
import tawk from "@/utils/plugins/tawk"

// Import assets File
import "@/assets/css/app.css";
import "@/assets/css/nprogress.css";
import "@/assets/js/app.js";

// Import Library
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Notifications from "notiwind";
import ClickOutside from "click-outside-vue3";
import lodash from "lodash";
import moment from "moment";
import LottieAnimation from "lottie-vuejs";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

store.$analytics = analytics;
store.$lodash = lodash;
store.$moment = moment;

const app = createApp(App)
  .use(router)
  .use(locale)
  .use(Notifications)
  .use(ClickOutside)
  .use(LottieAnimation)
  .use(tawk, {
    propertyId: process.env.VUE_APP_TAWKTO_PROPERTY_ID,
    widgetId: process.env.VUE_APP_TAWKTO_WIDGET_ID,
  });

store.$t = app.config.globalProperties.$t;
app.config.globalProperties.$analytics = analytics;
app.config.globalProperties.$lodash = lodash;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$formats = {
  currency(currency, value) {
    if (isNaN(value)) return;
    if (!currency) return value;
          
    try {
      return new Intl.NumberFormat(process.env.VUE_APP_LOCALE, {
        style: "currency",
        currency: currency,
      }).format(value);
    } catch (e) {
        return currency + " " + value.toFixed(2);
    }
  },
};

store.$isTestModeEnabled = store.getters["businessStore/test_mode"] ?? false;

store.$backendURL = store.$isTestModeEnabled
  ? process.env.VUE_APP_BACKEND_URL_TEST
  : process.env.VUE_APP_BACKEND_URL;

Sentry.init({
  app,
  environment: process.env.NODE_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "app.swipego.io", "stg.swipego.io", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(store);

// Register global
global(app);


// Mount App
app.mount("#app");

