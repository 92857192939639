<template>
  <div class="w-full flex flex-row justify-center">
    <div
      class="
        flex flex-row
        justify-self-center
        w-full
        justify-center
        items-center
        text-sm text-center
        p-4
        bg-white
      "
      :class="$attrs.class"
    >
      &copy; {{ $t("app.company_name") }}
      <!-- <span>&nbsp;.&nbsp;</span>
      <a
        class="text-black"
        href="https://drive.google.com/file/d/1PiQHTlAAidbW6mB2vj906lRA7N9HRfZa/view?usp=share_link"
        target="_blank"
      >
        {{ $t("general.term_and_conditions") }}
      </a>
      <span>&nbsp;.&nbsp;</span>
      <a class="text-black" target="_blank">
        {{ $t("general.contact") }}
      </a> -->
    </div>
  </div>
</template>
