import { VueReCaptcha } from 'vue-recaptcha-v3'
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import store from "@/stores/store";

function registerAppComponent(app) {

  const requireComponent = require.context('@/views/components', true, /app-[\w-]+\.vue$/)

  requireComponent.keys().forEach((fileName) => {

    const componentConfig = requireComponent(fileName)

    const componentName = fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, '')

    app.component(componentName, componentConfig.default || componentConfig)

  })
}

function registerReCaptchaComponent(app) {

  app.use(VueReCaptcha,
    {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loaderOptions: {
        autoHideBadge: true
      }
    })

  app.component('VueReCaptcha', VueReCaptcha);
}


function registerBroadcast(app) {

  store.dispatch("authStore/checkAuth", false).then((auth) => {

    if (auth?.token) {

      if (process.env.NODE_ENV == "development") {
        Pusher.logToConsole = true
      }

      let websocketHost = store.$isTestModeEnabled
        ? process.env.VUE_APP_WEBSOCKET_HOST_TEST
        : process.env.VUE_APP_WEBSOCKET_HOST;

      let websocketPort = store.$isTestModeEnabled
        ? process.env.VUE_APP_WEBSOCKET_PORT_TEST
        : process.env.VUE_APP_WEBSOCKET_PORT;

      app.config.globalProperties.$echo = new Echo(
        {
          forceTLS: true,
          broadcaster: "pusher",
          key: process.env.VUE_APP_WEBSOCKET_KEY,
          wsHost: websocketHost,
          wssHost: websocketHost,
          wsPort: websocketPort,
          wssPort: websocketPort,
          authEndpoint: store.$backendURL + "/api/broadcasting/auth",
          enabledTransports: ['ws', 'wss'],
          auth: {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          },
        }
      );
    }

  });
}

export default function register(app) {
  registerBroadcast(app)
  registerAppComponent(app)
  registerReCaptchaComponent(app)

}
