<template>
  <app-full-modal title="User Details">
    <template v-slot:body>
      <div class="p-5 space-y-5">
        <div class="space-y-3">
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("user.detail.id") }}
            </p>
            <p class="col-span-4">{{ user.id }}</p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("user.detail.verify_at") }}
            </p>
            <p class="col-span-4">
              {{
                user.email_verified_at
                  ? $moment(user.email_verified_at).format(
                      "DD MMM YYYY hh:mm a"
                    )
                  : "-"
              }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("user.detail.register_at") }}
            </p>
            <p class="col-span-4">
              {{ $moment(user.created_at).format("DD MMM YYYY hh:mm a") }}
            </p>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px"></p>
            <p class="col-span-4">
              <button @click="this.$emit('resend-password', user.email)">
                <div
                  class="
                    px-2
                    py-1
                    border
                    rounded-md
                    flex flex-row
                    justify-center
                    bg-background
                    place-items-center
                    hover:bg-gray-100
                    space-x-2
                  "
                >
                  <app-icon-loading
                    v-if="loadingAuth"
                    iconColor="bg-primary"
                    customClass="w-5 h-5"
                  />
                  <app-icon
                    v-else
                    name="MailIcon"
                    class="h-5 w-5 text-gray-400"
                  />

                  <p class="text-xs">{{ $t("general.reset_password") }}</p>
                </div>
              </button>
            </p>
          </div>
        </div>
        <hr />
        <form @submit.prevent="submitForm">
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("user.detail.name") }}
            </p>
            <div class="col-span-4">
              <app-form-input
                type="text"
                :showlabel="false"
                :placeholder="$t('user.detail.name')"
                v-model="form.name"
                :disabled="!editMode"
                :errorMessage="errors.name"
                :required="true"
              />
            </div>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("user.detail.email") }}
            </p>
            <div class="col-span-4">
              <app-form-input
                class="pt-2"
                id="email"
                name="email"
                type="email"
                :showlabel="false"
                :placeholder="$t('user.detail.email')"
                v-model="form.email"
                :disabled="!editMode"
                :errorMessage="errors.email"
                :required="true"
              />
            </div>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("user.detail.country") }}
            </p>
            <span class="col-span-4">
              <app-form-country
                :allowFilter="true"
                :showIcon="true"
                :showLabel="false"
                class="mt-0"
                v-model="form.country"
                :disabled="!editMode"
                :errorMessage="errors.country_id"
              />
            </span>
          </div>

          <div class="text-right space-x-1 my-5" v-if="editMode">
            <app-button-outline
              :showf70Icon="false"
              width="w-auto"
              @click="(editMode = false), getUser()"
            >
              <p>{{ $t("general.cancel") }}</p>
            </app-button-outline>
            <app-button :showf70Icon="false" width="w-auto" type="submit">
              <p>{{ $t("general.save") }}</p>
            </app-button>
          </div>
          <div class="text-right space-x-1 mt-5" v-else>
            <app-button-outline
              :showf70Icon="false"
              width="w-auto"
              @click="editMode = true"
            >
              <p>{{ $t("general.edit") }}</p>
            </app-button-outline>
          </div>
        </form>

        <hr />

        <div class="space-y-3">
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px">
              {{ $t("user.detail.role") }}
            </p>
            <div class="col-span-4 space-y-3">
              <div>
                <app-form-select
                  class="flex-auto"
                  :datas="roles"
                  :errorMessage="errors.role_id"
                  :allowFilter="false"
                  v-on:retry="getRoles"
                  v-model="select_role"
                />
              </div>

              <div>
                <app-form-select
                  v-if="showSelectBusinessRole"
                  class="flex-auto"
                  :datas="all_business"
                  :errorMessage="errors.business_id"
                  :showLabel="false"
                  v-model="select_business"
                />
              </div>

              <div v-if="!$lodash.isEmpty(select_role)" class="flex flex-col">
                <app-button-outline
                  :showf70Icon="false"
                  width="w-auto"
                  @click="addUserRole"
                  :loading="loading"
                >
                  <p>{{ $t("general.add") }}</p>
                </app-button-outline>
              </div>
            </div>
          </div>
          <div class="block sm:grid grid-cols-5 text-sm">
            <p class="col-span-1 text-gray-400" width="120px"></p>
            <div class="col-span-4 space-y-2">
              <div
                v-for="(model_has_role, index) in user.model_has_roles"
                :key="index"
                class="
                  flex
                  justify-between
                  place-items-center
                  bg-gray-50
                  rounded-md
                  border
                  px-3
                  py-2
                  h-14
                "
              >
                <div class="flex place-items-center space-x-2">
                  <div>
                    <app-icon
                      v-if="model_has_role.business"
                      name="BriefcaseIcon"
                      class="h-5 w-5 mr-2 flex-none"
                    />
                    <app-icon v-else name="UserIcon" class="h-5 w-5 mr-2" />
                  </div>
                  <div>
                    <p class="uppercase font-medium">
                      {{ model_has_role.role.name.replaceAll("_", " ") }}
                    </p>
                    <p class="text-sm" v-if="model_has_role.business">
                      {{ $t("user.detail.business_name") }} :
                      {{ model_has_role.business?.name }}
                    </p>
                  </div>
                </div>
                <div class="space-x-2">
                  <button
                    v-if="
                      selectedRole.role?.id != model_has_role.role?.id &&
                      model_has_role.role?.id != ROLE.USER
                    "
                    class="p-1 rounded-sm bg-red-500 hover:bg-red-600"
                    @click="deleteUserRole(model_has_role.id)"
                  >
                    <app-icon-outline name="TrashIcon" class="w-5 text-white" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
import ROLE from "@/utils/const/role";
import BUSINESS_STATUS from "@/utils/const/business_status";

export default {
  data() {
    return {
      ROLE: ROLE,
      editMode: false,
      select_role: null,
      select_role_error: [],
      select_business: [],
      select_business_error: [],
      form: {
        name: null,
        email: null,
        country: null,
      },
    };
  },
  props: {
    selectUser: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["userStore/loading"];
    },
    loadingAuth() {
      return this.$store.getters["authStore/loading"];
    },
    loadingBusiness() {
      return this.$store.getters["businessStore/loading"];
    },
    errors() {
      return this.$store.getters["userStore/errors"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    user: {
      get() {
        return this.selectUser;
      },
      set(value) {
        this.$emit("update:selectUser", value);
        this.$emit("refresh");
      },
    },
    showSelectBusinessRole() {
      return [
        ROLE.BUSINESS_OWNER,
        ROLE.BUSINESS_ADMIN,
        ROLE.BUSINESS_MANAGER,
        ROLE.BUSINESS_DEVELOPER,
      ].includes(this.select_role?.id);
    },
    roles() {
      return this.$store.getters["referenceStore/roles"]
        .filter(
          (role) =>
            !this.user.model_has_roles
              .map((modelHasRole) => modelHasRole.role_id)
              .includes(role.id) ||
            [
              ROLE.BUSINESS_OWNER,
              ROLE.BUSINESS_ADMIN,
              ROLE.BUSINESS_MANAGER,
              ROLE.BUSINESS_DEVELOPER,
            ].includes(role.id)
        )
        // filter role
        .filter(
          (role) => [
              ROLE.ADMIN,
              ROLE.OPERATION,
              ROLE.SUPPORT,
              ROLE.ACCOUNT,
              ROLE.BUSINESS_OWNER,
            ].includes(role.id)
        )
        .map((role) => {
          return {
            id: role.id,
            name: role.name.replaceAll("_", " "),
          };
        });
    },
  },
  watch: {
    user: function (newValue, oldValue) {
      this.select_role = {};
      this.select_business = {};

      this.form = {
        name: this.user.name,
        email: this.user.email,
        country: this.user.country,
      };
    },
  },
  mounted() {
    this.getRoles();
    this.getAllBusiness();
  },
  methods: {
    async getRoles() {
      this.$store.dispatch("referenceStore/getRoles");
    },

    async getAllBusiness() {
      this.$store.dispatch("businessStore/getAllBusiness", {
        business_status_id: BUSINESS_STATUS.SUBMITED,
      });
    },

    async submitForm() {
      this.resetState();

      const result = await this.$store.dispatch("userStore/updateUser", {
        id: this.user.id,
        name: this.form.name,
        email: this.form.email,
        country_id: this.form.country.id,
      });

      if (result != null) {
        this.editMode = false;
        this.$notify(
          {
            group: "success",
            title: this.$t("message.update_successful"),
          },
          3000
        );
        this.getUser();
      }
    },

    async addUserRole() {
      if (this.$lodash.isEmpty(this.select_role)) {
        this.errors.role_id = [this.$t("general.required")];
      } else if (
        this.showSelectBusinessRole &&
        this.$lodash.isEmpty(this.select_business)
      ) {
        this.errors.business_id = [this.$t("general.required")];
      } else {
        this.resetState();

        if (
          !this.showSelectBusinessRole &&
          !this.$lodash.isEmpty(this.select_business)
        ) {
          this.select_business = {};
        }

        const result = await this.$store.dispatch("userStore/createRoleUser", {
          role_id: this.select_role.id,
          business_id: this.select_business.id,
          user_id: this.user.id,
        });

        if (result != null) {
          this.select_role = {};
          this.select_business = {};
          this.$store.dispatch("authStore/refreshUser");
          this.$notify(
            {
              group: "success",
              title: this.$t("message.update_successful"),
            },
            3000
          );

          this.getUser();
        }
      }
    },

    async getUser() {
      const result = await this.$store.dispatch(
        "userStore/getUser",
        this.user.id
      );

      if (result != null) {
        this.user = result;
      }
    },

    async deleteUserRole(id) {
      const confirm_delete = confirm(this.$t("user.detail.confirm_delete"));

      if (confirm_delete) {
        const result = await this.$store.dispatch(
          "userStore/deleteRoleUser",
          id
        );

        if (result != null) {
          this.$store.dispatch("authStore/refreshUser");
          this.$notify(
            {
              group: "success",
              title: this.$t("message.update_successful"),
            },
            3000
          );
          this.getUser();
        }
      }
    },

    resetState() {
      this.$store.commit("userStore/resetState", {
        errors: [],
        message: null,
      });
    },
  },
};
</script>