<template>
  <button
    class="
      focus:outline-none
      text-sm
      rounded-md
      border
      inline-flex
      font-medium
      justify-center
      transition
      items-center
    "
    type="button"
    :class="[
      { actived: active },
      padding,
      disabled
        ? 'cursor-not-allowed text-gray-300'
        : 'cursor-pointer text-gray-500 hover:bg-gray-100',
    ]"
    @click="onClick()"
    :disabled="disabled || loading"
  >
    <svg
      fill="none"
      v-if="loading"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      class="animate-spin mt-0.5 -ml-1 mr-3 h-4 w-4 text-secondary"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke-width="4"
        class="opacity-25"
        stroke="currentColor"
      ></circle>

      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot />
  </button>
</template>


<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    padding: {
      type: String,
      default: "px-4 py-2",
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      this.$emit("onClick", event);
    },
  },
};
</script>

<style scoped>
.actived {
  @apply bg-secondary text-white !important;
}
</style>