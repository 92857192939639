<template>
  <button
    class="
      relative
      px-4
      py-2
      rounded-md
      inline-flex
      disabled:opacity-50
      border
      transition-all
      text-sm
    "
    :class="[
      width,
      loading ? 'cursor-wait' : 'cursor-pointer',
      primary ? 'bg-secondary hover:bg-primary focus:bg-click-button text-white border-transparent' :
      'bg-white hover:bg-gray-200 focus:ring focus:ring-gray-200 text-black border-gray-300',
      active ? 'ring ring-gray-200' : 'ring-none',
      'justify-' + alignment,
      'items-' +  alignment, 
    ]"
    @click="onClick()"
    :disabled="loading || disabled"
    :type="type"
  >
    <app-icon-small-logo-with-border v-if="showf70Icon" class="absolute left-3 text-white" />
    <ArrowNarrowRightIcon v-if="showProceedIcon" class="absolute h-6 w-6 right-3 text-white" />

    <slot v-if="!loading" name="icon"></slot>
    <app-icon-loading :customClass="['h-5 w-5 mx-2', primary ? 'text-white' : 'text-black']" v-if="loading" />
    <slot v-else />
  </button>
</template>

<script>
import { logEvent } from "firebase/analytics";
import { ArrowNarrowRightIcon } from "@heroicons/vue/outline";

export default {
  components: {
    ArrowNarrowRightIcon
  },

  props: {
    width: {
      type: String,
      default: 'w-full'
    },
    alignment: {
      type: String,
      default: 'center'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    eventName: {
      type: String,
      default: null,
    },
    showf70Icon: {
      type: Boolean,
      default: true
    },
    showProceedIcon: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    onClick(event) {
      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("onClick", event);
    },
  },
};
</script>