import store from '@/stores/store';
import BUSINESS_STATUS from "@/utils/const/business_status";

export default (to, from, next) => {

    if (store.getters["authStore/selectedRole"]?.business?.business_status_id != BUSINESS_STATUS.APPROVED) {
        next({ name: 'not-found' })
    }

}
