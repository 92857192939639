<template>
  <button
    v-if="selectedRole?.business"
    v-show="business.business_status_id"
    @click="routeTo()"
    class="px-4 gap-2 flex flex-row items-center "
    :class="[
      full_width
        ? 'w-full justify-center rounded py-2 mb-1'
        : 'py-1 rounded-lg',
      statusInfo.style,
    ]"
  >
    <span :class="[statusInfo.textStyle]">
      {{ statusInfo.text }}
    </span>
    <app-icon :name="statusInfo.icon" class="h-4 w-4" />
  </button>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
    };
  },
  props: {
    full_width: {
      type: Boolean,
      default: false,
    },
    route_name: {
      type: String,
      default: null,
    },
  },
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    statusInfo() {
      switch (this.business?.business_status_id) {
        case BUSINESS_STATUS.CREATED:
          return {
            text: this.$t("layout.header.activate_account"),
            icon: "ArrowCircleRightIcon",
            style: "status-created",
            textStyle: "text-yellow-600",
          };
        case BUSINESS_STATUS.SUBMITTED:
          return {
            text: this.$t("layout.header.account_under_review"),
            icon: "ArrowCircleRightIcon",
            style: "status-submitted",
            textStyle: "text-white",
          };
        case BUSINESS_STATUS.APPROVED:
          return {
            text: this.$t("layout.header.account_approved"),
            icon: "CheckCircleIcon",
            style: "status-approved",
            textStyle: "text-white",
          };
        case BUSINESS_STATUS.AMENDED:
          return {
            text: this.$t("layout.header.account_amended"),
            icon: "ArrowCircleRightIcon",
            style: "status-amended",
            textStyle: "text-yellow-900",
          };
        case BUSINESS_STATUS.REJECTED:
          return {
            text: this.$t("layout.header.account_rejected"),
            icon: "ExclamationIcon",
            style: "status-rejected",
            textStyle: "text-white",
          };
          case BUSINESS_STATUS.SUSPENDED:
          return {
            text: this.$t("layout.header.account_suspended"),
            icon: "ExclamationIcon",
            style: "status-suspended",
            textStyle: "text-yellow-900",
          };
        default:
          return {
            text: "-",
            icon: "ArrowCircleRightIcon",
            style: "status-created",
            textStyle: "text-white",
          };
      }
    },
  },
  methods: {
    routeTo() {
      if (this.route_name) {
        this.$router.push({ name: this.route_name });
      }
    },
  },
};
</script>


<style scoped>
.status-created {
  @apply bg-yellow-200
  hover:bg-yellow-300
  text-yellow-700 text-sm;
}
.status-submitted {
  @apply bg-gray-500
  hover:bg-gray-600
  text-white text-sm;
}
.status-approved {
  @apply bg-green-500
  hover:bg-green-600
  text-white text-sm;
}
.status-amended {
  @apply bg-white
  hover:bg-gray-100
  text-yellow-900 text-sm;
}
.status-rejected {
  @apply bg-red-600
  hover:bg-red-700
  text-white text-sm;
}

.status-suspended {
  @apply bg-yellow-400
  hover:bg-yellow-300
  text-yellow-700 text-sm;
}
</style>
